
export const LoginDetailsReducer = (state = [], action) => {
    switch (action.type) {
      case `SET_CONFERNECE_LOGIN_DETAILS`:
        return action.payload;
      default:
        return state;
    }
  };

export const ApplicantDetailsReducer = (state = [], action) => {
    switch (action.type) {
        case `SET_APPLICANT_DETAILS`:
            return action.payload;
        default:
            return state;
    }
};

