import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../common/loader";
import PageTitle from "../common/pagetitle/pagetitle";
import { showAlert } from "../common/sweetalert/sweetalert";
import { serverLink } from "../url";
import JoditEditor from "jodit-react";
import male_avater from '../img/male_avater.jpg'


const AddFacilitator = (props) => {
    const editorBio = React.createRef();
    const [isloading, setIsLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [tutorList, setTutorList] = useState([]);

    const [formData, setFormData] = useState({
        StaffName: "",
        EmailAddress: "",
        Bio: "",
        LinkedInUrl: "",
        Status: "",
        ImageUrl: "",
        ScholarUrl: "",
        FacebookUrl: "",
        TwitterUrl: "",
        Designation:"",
        Qualification:"",
        InsertedBy: props.loginData[0].EmailAddress,
        EntryID: "",
    })
    const getData = async () => {
        await axios.get(`${serverLink}foundation/login/get-tutor-list`).then((res) => {
            if (res.data.length > 0) {
                setTutorList(res.data)
            }
        })
        setIsLoading(false)
    }

    useEffect(() => {
        getData();
    }, [])

    const addTutor = async (e) => {
        e.preventDefault();
        if (formData.StaffName.trim() === "") {
            toast.error("Please enter StaffName");
            return false;
        }
        setLoading(true);
        try {
            if (formData.EntryID === "") {
                await axios.post(`${serverLink}foundation/login/add/tutor`, formData).then((res) => {
                    if (res.data.message === "success") {
                        if (formData.ImageUrl !== "") {
                            const dt = new FormData()
                            dt.append('entry_id', res.data.entry_id)
                            dt.append('File', formData.ImageUrl)
                            axios.patch(`${serverLink}foundation/login/upload-photo-tutor`, dt).then((res) => {
                            })
                        }
                        setLoading(false);
                        getData();
                        Reset();
                        showAlert("success", "Record added successfully", "success");
                        document.getElementById("btn_close").click();
                    } else if (res.data.message === "exist") {
                        setLoading(false)
                        showAlert("warning", "Record with this email already exist", "warning")
                    } else {
                        showAlert("error", "Network error, please check your connection", "error")
                    }
                })
            } else {
                await axios.patch(`${serverLink}foundation/login/update/tutor`, formData).then((res) => {
                    if (res.data.message === "success") {
                        if (formData.ImageUrl !== "") {
                            const dt = new FormData()
                            dt.append('entry_id', formData.EntryID)
                            dt.append('File', formData.ImageUrl)
                            axios.patch(`${serverLink}foundation/login/upload-photo-tutor`, dt).then((res) => {
                            })
                        }
                        setLoading(false);
                        getData();
                        Reset();
                        showAlert("success", "Record Updated Successfully", "success");
                        document.getElementById("btn_close").click();
                    }
                })
            }
        } catch (e) {
            console.log(e)
            setLoading(false)
            showAlert("error", "Network Error, try again", "error");
        }

    }

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        })

        if (e.target.id === "file") {
            if (e.target.files[0].size > 1000000) {
                toast.error("max file size is 1mb", "error")
                return false;
            } else {
                setFormData({
                    ...formData,
                    ImageUrl: e.target.files[0]
                })
            }
        }
    }


    const onBioChange = (e) => {
        setFormData({
            ...formData,
            Bio: e
        })
    }

    const Reset = () => {
        setFormData({
            ...formData,
            StaffName: "",
            EmailAddress: "",
            Bio: "",
            LinkedInUrl: "",
            ImageUrl: "",
            ScholarUrl: "",
            FacebookUrl: "",
            TwitterUrl: "",
            EntryID: "",
        })
    }

    return isloading === true ?
        (<Loader />) : (
            <>
                <PageTitle title={["Administrator", "Add Facilitator"]} />
                <section className="section">
                    <div className="row align-items-top">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="d-flex justify-content-start">
                                        <h4>Facilitator</h4>
                                    </div>
                                    <div
                                        className="d-flex justify-content-end"
                                        data-kt-customer-table-toolbar="base"
                                    >
                                        <button data-bs-toggle="modal" data-bs-target="#basicModal"
                                                className="btn btn-sm btn-primary text-white"
                                                onClick={() => {
                                                    setFormData({
                                                        ...formData,
                                                        StaffName: "",
                                                        EmailAddress: "",
                                                        Bio: "",
                                                        LinkedInUrl: "",
                                                        Status: "",
                                                        Photo: "",
                                                        ScholarUrl: "",
                                                        FacebookUrl: "",
                                                        TwitterUrl: "",
                                                        EntryID: "",
                                                        ImageUrl: "",
                                                        Designation:"",
                                                        Qualification:""
                                                    })
                                                }}
                                        >
                                            <i className="fa fa-plus" /> Add Facilitator
                                        </button>
                                    </div>
                                </div>

                                <div className="card-body">
                                    <div className="table table-responsive-lg">
                                        <table className="table table-striped">
                                            <thead>
                                            <tr>
                                                <th scope="col">S/No</th>
                                                <th scope="col">Staff Name</th>
                                                <th scope="col">Email Address</th>
                                                <th scope="col">Qualification</th>
                                                <th scope="col"> Action</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                tutorList.length > 0 ?
                                                    tutorList.map((x, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>{i + 1}</td>
                                                                <td style={{ maxWidth: "450px" }}>{x.StaffName}</td>
                                                                <td>{x.EmailAddress}</td>
                                                                <td>{x.Qualification}</td>
                                                                <td>
                                                                    <button type="button" id="btnModal" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#basicModal" onClick={() => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            StaffName: x.StaffName,
                                                                            EmailAddress: x.EmailAddress,
                                                                            Bio: x.Description,
                                                                            Status: x.Status,
                                                                            LinkedInUrl: x.LinkedInUrl,
                                                                            ImageUrl: x.ImageUrl,
                                                                            ScholarUrl: x.ScholarUrl,
                                                                            FacebookUrl: x.FacebookUrl,
                                                                            TwitterUrl: x.TwitterUrl,
                                                                            EntryID: x.EntryID,
                                                                            Photo: x.ImageUrl,
                                                                            Designation:x.Designation,
                                                                            Qualification: x.Qualification
                                                                        })
                                                                    }}> Edit
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr>
                                                        <td colSpan={7}>No Record</td>
                                                    </tr>
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="modal fade" id="basicModal" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content modal-lg">
                            <div className="modal-header">
                                <h5 className="modal-title">Facilitator</h5>
                                <button type="button" id="btn_close" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body">
                                <form onSubmit={addTutor}>
                                    <div className="row">
                                        {formData.EntryID !== "" &&
                                            <div className="text-center mb-2">
                                                <img src={formData.Photo === "" || formData.Photo === null ? male_avater : `${serverLink}public/uploads/ideas_project/facilitator/${formData.Photo}`} alt="Profile" width={150} height={150} />
                                            </div>}
                                        <div className="col-lg-6">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="StaffName" className="col-md-12 col-lg-12 col-form-label">Staff Name</label>
                                                    <input type="text" className="form-control" id="StaffName" value={formData.StaffName} required onChange={onEdit} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="EmailAddress" className="col-md-12 col-lg-12 col-form-label">Email Address</label>
                                                    <input type="text" className="form-control" id="EmailAddress" value={formData.EmailAddress} onChange={onEdit} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="Designation" className="col-md-12 col-lg-12 col-form-label">Designation</label>
                                                    <input type="text" className="form-control" id="Designation" value={formData.Designation} onChange={onEdit} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="LinkedInUrl" className="col-md-12 col-lg-12 col-form-label">LinkedIn Url</label>
                                                    <input type="text" className="form-control" id="LinkedInUrl" value={formData.LinkedInUrl} required onChange={onEdit} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="ScholarUrl" className="col-md-12 col-lg-12 col-form-label">Scholar Url</label>
                                                    <input type="text" className="form-control" id="ScholarUrl" value={formData.ScholarUrl} required onChange={onEdit} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="Qualification" className="col-md-12 col-lg-12 col-form-label">Qualification</label>
                                                    <input type="text" className="form-control" id="Qualification" value={formData.Qualification} required onChange={onEdit} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="TwitterUrl" className="col-md-12 col-lg-12 col-form-label">Twitter Url</label>
                                                    <input type="text" className="form-control" id="TwitterUrl" value={formData.TwitterUrl} required onChange={onEdit} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="Status" className="col-md-12 col-lg-12 col-form-label">Status</label>
                                                    <select className="form-control form-select" onChange={onEdit} id="Status" value={formData.Status}>
                                                        <option value={""}>-select Status-</option>
                                                        <option value="1">Active</option>
                                                        <option value="0">In-Active</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="row mb-3">
                                                <div className="col-md-12 col-lg-12">
                                                    <label htmlFor="file" className="col-md-4 col-lg-3 col-form-label">Passport </label>
                                                    (<small className="text-danger">Max size is 1mb</small>)
                                                    <input type="file" accept="image/*" className="form-control" id="file" onChange={onEdit} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="Bio" className="col-md-12 col-lg-12 col-form-label">Bio</label>
                                                    <JoditEditor
                                                        value={formData.Bio}
                                                        ref={editorBio}
                                                        tabIndex={1}
                                                        onChange={onBioChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="row mb-3">
                                                <div className="col-md-12 text-center">
                                                    <button className="btn btn-lg btn-primary col-md-6" type="submit" disabled={loading ? true : false}>
                                                        {
                                                            loading === true &&
                                                            <>
                                                                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" />&emsp;
                                                            </>
                                                        }
                                                        {loading === true ? "Loading..." :
                                                            "Submit"}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(AddFacilitator);
