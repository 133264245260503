import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import Loader from "../common/loader";
import PageTitle from "../common/pagetitle/pagetitle";
import { showAlert } from "../common/sweetalert/sweetalert";
import male_avater from '../img/male_avater.jpg'
import { encryptData, serverLink } from "../url";
import JoditEditor from "jodit-react";


const Profile = (props) => {
  const editorRef = React.createRef();
  const param = window.location.href.split("&")[1];
  const [isloading, setIsLoading] = useState(true)
  const navigate = useNavigate();
  const [team, setTeam] = useState([]);
  const [formData, setFormData] = useState({
    FullName: "",
    EmailAddress: "",
    Bio: "",
    LinkedInUrl: "",
    ScholarUrl: "",
    FacebookUrl: "",
    TwitterUrl: "",
    CurrentPassword: "",
    NewPassword: "",
    RenewPassword: "",
    Photo: "",
    FirstName:"",
    OtherNames:"",
    Role:"",
    EntryID: param !== undefined ? param : props.loginData[0].EntryID
  })

  const onEdit = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value
    })
    if (e.target.id === "Photo") {
      setFormData({
          ...formData,
          Photo: e.target.files[0]
      })
  }
  }

  const getData = async () => {
    await axios.post(`${serverLink}foundation/login/get_record`, { EntryID: formData.EntryID })
      .then((res) => {
        if (res.data.length > 0) {
          setFormData({
            ...formData,
            FirstName: res.data[0].FirstName,
            OtherNames:res.data[0].OtherNames,
            EmailAddress: res.data[0].EmailAddress,
            Role: res.data[0].Role,
          })
          setTeam(res.data)
        }
        setIsLoading(false)
      })
      .catch(() => {

      })
  }

  const updateProfile = async (e) => {
    e.preventDefault();
      try {
        toast.info("please wait...");
        await axios.patch(`${serverLink}foundation/login/update/profile`, formData)
          .then((res) => {
            if (res.data.message === "success") {
              getData();
              showAlert("success", "Profile updated successfully", "success");
            }

          })
          .catch(() => {
            showAlert("error", "Network error", "error")
          })
      } catch (e) {

      }


  }

  // const updateProfile = async (e) => {
  //   let file = e.target.files[0];
  //   e.preventDefault();
  //   if (!file.type.includes("image/")) {
  //     toast.error("Only Images are allowed");
  //     return;
  //   }else{
  //     try {
  //       toast.info("please wait...");
  //       await axios.patch(`${serverLink}foundation/login/update/team`, formData)
  //         .then((res) => {
  //           if (res.data.message === "success") {
  //             if (formData.Photo !== "") {
  //               const dt = new FormData();
  //               dt.append("File", formData.Photo);
  //               dt.append("entry_id", res.data.entry_id)
  //               axios.patch(`${serverLink}foundation/login/upload-photo`, dt).then((res) => {

  //               })
  //             }
  //             getData();
  //             showAlert("success", "Profile updated successfully", "success");
  //           }

  //         })
  //         .catch(() => {
  //           showAlert("error", "Network error", "error")
  //         })
  //     } catch (e) {

  //     }
  //   }

  // }

  const ChangePassword = async (e) => {
    e.preventDefault();
    if (formData.CurrentPassword === "") {
      showAlert("error", "Enter Old Password", "error")
      return false;
    }
    if (encryptData(formData.CurrentPassword) !== team[0].Password) {
      showAlert("error", "Old Password Incorrect", "error")
      return false;
    }
    if (formData.NewPassword === "") {
      showAlert("error", "Enter new password", "error")
      return false;
    }
    if (formData.RenewPassword === "") {
      showAlert("error", "Re-enter new password", "error")
      return false;
    }
    if (formData.NewPassword !== formData.RenewPassword) {
      showAlert("error", "Password do not match", "error")
      return false;
    }
    try {
      await axios.patch(`${serverLink}foundation/login/update-password/${formData.EntryID}`, { Password: encryptData(formData.NewPassword) })
        .then((res) => {
          if (res.data.message === "success") {
            showAlert("success", "password reset successful", "success")
              .then(() => {
                navigate('/dashboard')
              });
          }
        })
    } catch (e) {
      showAlert("error", "please try again", "error")
    }

  }

  const uploadPicture = async (e) => {
    let file = e.target.files[0];
    const dt_ = new FormData();
    dt_.append("Photo", file);
    dt_.append("EntryID", formData.EntryID);
    if (!file.type.includes("image/")) {
      toast.error("Only Images are allowed");
      return;
    } else {
      toast.info("please wait...");
      await axios.patch(`${serverLink}foundation/login/update-photo`, dt_).then((res) => {
        if (res.data.message === "success") {
          getData();
          toast.success("photo updated")
        } else {

        }
      })

    }
  }

  useEffect(() => {
    getData();
  }, [])

  const onCommentChange = (e) => {
    setFormData({
      ...formData,
      Bio: e
    })
  }


  return isloading ? (<Loader />) : (
    <>
      <PageTitle title={["Home", "Profile"]} />
      <section className="section profile">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body pt-3">
                {/* Bordered Tabs */}
                <ul className="nav nav-tabs nav-tabs-bordered" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#profile-overview" aria-selected="false" role="tab" tabIndex={-1}>Overview</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link" data-bs-toggle="tab" data-bs-target="#profile-edit" aria-selected="true" role="tab">Edit Profile</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link" data-bs-toggle="tab" data-bs-target="#profile-change-password" aria-selected="false" role="tab" tabIndex={-1}>Change Password</button>
                  </li>
                </ul>
                <div className="tab-content pt-2">
                  {
                    team.length > 0 &&
                    <div className="tab-pane fade profile-overview active show" id="profile-overview" role="tabpanel">
                      <div className="card">
                        <div className="card-body">
                          <h5 className="card-title">About</h5>
                          <h5 className="card-title">Profile Details</h5>
                          <div className="row">
                            <div className="col-lg-3 col-md-4 label ">Full Name</div>
                            <div className="col-lg-9 col-md-8">{formData.FirstName + " " + formData.OtherNames}</div>
                          </div>
                          <div className="row">
                            <div className="col-lg-3 col-md-4 label">EmailAddress</div>
                            <div className="col-lg-9 col-md-8">{formData.EmailAddress}</div>
                          </div>
                          <div className="row">
                            <div className="col-lg-3 col-md-4 label">Role</div>
                            <div className="col-lg-9 col-md-8">{formData.Role}</div>
                          </div>
                          {/* <div className="row">
                            <div className="col-lg-3 col-md-4 label">Bio</div>
                            <div className="col-lg-9 col-md-8" dangerouslySetInnerHTML={{ __html: formData.Bio }} />
                          </div> */}
                        </div>
                      </div>
                    </div>
                  }

                  <div className="tab-pane fade profile-edit pt-3 show" id="profile-edit" role="tabpanel">
                    <div className="card">
                      <div className="card-body">
                        <h5 className="card-title">Edit your profile</h5>
                        <div className="row mb-3 pb-3">
                          {/* <label htmlFor="profileImage" className="col-md-4 col-lg-3 col-form-label">Profile Image</label> */}
                          <div className="col-md-8 col-lg-9">
                            <div className="row col-md-12">
                              <div className="col-md-6">
                                {/* <img src={formData.Photo === "" || formData.Photo === null ? male_avater : `${serverLink}public/uploads/ideas/team/${formData.Photo}`} alt="Profile" /> */}
                                {/* <div className="pt-2">
                                  <span className="btn btn-primary btn-sm" title="Upload new profile image">
                                    <input type={"file"} accept="image/*" id="Photo" onChange={onEdit} style={{ display: 'hidden' }} />
                                    <i className="bi bi-upload" />
                                  </span>
                                </div> */}
                              </div>

                            </div>
                          </div>
                        </div>
                        <form className="row g-3" onSubmit={updateProfile}>
                          {/* <div className="row mb-3">
                            <label htmlFor="Title" className="col-md-4 col-lg-3 col-form-label">Title</label>
                            <div className="col-md-8 col-lg-9">
                              <input type="text" className="form-control" id="Title" value={formData.Title} required onChange={onEdit} />
                            </div>
                          </div> */}
                          <div className="row mb-3">
                            <label htmlFor="FirstName" className="col-md-4 col-lg-3 col-form-label">Full Name</label>
                            <div className="col-md-8 col-lg-9">
                              <input type="text" className="form-control" id="FirstName" value={formData.FirstName} required onChange={onEdit} />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <label htmlFor="OtherNames" className="col-md-4 col-lg-3 col-form-label">Other Names</label>
                            <div className="col-md-8 col-lg-9">
                              <input type="text" className="form-control" id="OtherNames" value={formData.OtherNames} required onChange={onEdit} />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <label htmlFor="EmailAddress" className="col-md-4 col-lg-3 col-form-label">Email Address</label>
                            <div className="col-md-8 col-lg-9">
                              <input type="text" className="form-control" id="EmailAddress" value={formData.EmailAddress} required onChange={onEdit} />
                            </div>
                          </div>
                          {/* <div className="row mb-3">
                            <label htmlFor="LinkedInUrl" className="col-md-4 col-lg-3 col-form-label">LinkedIn Url</label>
                            <div className="col-md-8 col-lg-9">
                              <input type="text" className="form-control" id="LinkedInUrl" value={formData.LinkedInUrl} required onChange={onEdit} />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <label htmlFor="ScholarUrl" className="col-md-4 col-lg-3 col-form-label">Scholar Url</label>
                            <div className="col-md-8 col-lg-9">
                              <input type="text" className="form-control" id="ScholarUrl" value={formData.ScholarUrl} required onChange={onEdit} />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <label htmlFor="FacebookUrl" className="col-md-4 col-lg-3 col-form-label">Facebook Url</label>
                            <div className="col-md-8 col-lg-9">
                              <input type="text" disabled className="form-control" id="FacebookUrl" value={formData.FacebookUrl} required onChange={onEdit} />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <label htmlFor="TwitterUrl" className="col-md-4 col-lg-3 col-form-label">Twitter Url</label>
                            <div className="col-md-8 col-lg-9">
                              <input type="text" disabled className="form-control" id="TwitterUrl" value={formData.TwitterUrl} required onChange={onEdit} />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <label htmlFor="TwitterUrl" className="col-md-4 col-lg-3 col-form-label">Bio</label>
                            <div className="col-md-8 col-lg-9">
                              <JoditEditor
                                value={formData.Bio}
                                ref={editorRef}
                                tabIndex={1}
                                onChange={onCommentChange}
                              />
                            </div>
                          </div> */}
                          <div className="col-12">
                            <button className="btn btn-primary" type="submit">Update Profile</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>

                  <div className="tab-pane fade pt-3" id="profile-change-password" role="tabpanel">
                    <form onSubmit={ChangePassword}>
                      <div className="row mb-3">
                        <label htmlFor="currentPassword" className="col-md-4 col-lg-3 col-form-label">Current Password</label>
                        <div className="col-md-8 col-lg-9">
                          <input name="password" type="password" onChange={onEdit} className="form-control" id="CurrentPassword" />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label htmlFor="newPassword" className="col-md-4 col-lg-3 col-form-label">New Password</label>
                        <div className="col-md-8 col-lg-9">
                          <input name="newpassword" type="password" className="form-control" id="NewPassword" onChange={onEdit} />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label htmlFor="renewPassword" className="col-md-4 col-lg-3 col-form-label">Re-enter New Password</label>
                        <div className="col-md-8 col-lg-9">
                          <input name="renewpassword" type="password" className="form-control" id="RenewPassword" onChange={onEdit} />
                        </div>
                      </div>
                      <div className="text-center">
                        <button type="submit" className="btn btn-primary">Change Password</button>
                      </div>
                    </form>{/* End Change Password Form */}
                  </div>
                </div>{/* End Bordered Tabs */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    loginData: state.LoginDetails,
  };
};

export default connect(mapStateToProps, null)(Profile);
