import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../common/loader";
import PageTitle from "../common/pagetitle/pagetitle";
import { showAlert } from "../common/sweetalert/sweetalert";
import { serverLink } from "../url";
import JoditEditor from "jodit-react";
import male_avater from '../img/male_avater.jpg'


const AddNews = (props) => {
    const editorBio = React.createRef();
    const [isloading, setIsLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [newsList, setNewsList] = useState([]);

    const [formData, setFormData] = useState({
        Title: "",
        Description: "",
        Author: "",
        Status: "",
        ImagePath: "",
        EntryID: "",
        InsertedBy: props.loginData[0].EmailAddress,
    })
    const getData = async () => {
        await axios.get(`${serverLink}foundation/login/get-news-list`).then((res) => {
            if (res.data.length > 0) {
                setNewsList(res.data)
            }
        })
        setIsLoading(false)
    }

    useEffect(() => {
        getData();
    }, [])

    const addNews = async (e) => {
        e.preventDefault();
        if (formData.Title.trim() === "") {
            toast.error("Please enter Title");
            return false;
        }
        setLoading(true);
        try {
            if (formData.EntryID === "") {
                await axios.post(`${serverLink}foundation/login/add/news`, formData).then((res) => {
                    if (res.data.message === "success") {
                        if (formData.ImagePath !== "") {
                            const dt = new FormData()
                            dt.append('entry_id', res.data.entry_id)
                            dt.append('File', formData.ImagePath)
                            axios.patch(`${serverLink}foundation/login/upload-photo-news`, dt).then((res) => {
                            })
                        }
                        setLoading(false);
                        getData();
                        Reset();
                        showAlert("success", "Record added successfully", "success");
                        document.getElementById("btn_close").click();
                    } else if (res.data.message === "exist") {
                        setLoading(false)
                        showAlert("warning", "News with this title already exist", "warning")
                    } else {
                        showAlert("error", "Network error, please check your connection", "error")
                    }
                })
            } else {
                await axios.patch(`${serverLink}foundation/login/update/news`, formData).then((res) => {
                    if (res.data.message === "success") {
                        if (formData.ImagePath !== "") {
                            const dt = new FormData()
                            dt.append('entry_id', formData.EntryID)
                            dt.append('File', formData.ImagePath)
                            axios.patch(`${serverLink}foundation/login/upload-photo-news`, dt).then((res) => {
                            })
                        }
                        setLoading(false);
                        getData();
                        Reset();
                        showAlert("success", "Record Updated Successfully", "success");
                        document.getElementById("btn_close").click();
                    }
                })
            }
        } catch (e) {
            console.log(e)
            setLoading(false)
            showAlert("error", "Network Error, try again", "error");
        }

    }

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        })

        if (e.target.id === "file") {
            if (e.target.files[0].size > 1000000) {
                toast.error("max file size is 1mb", "error")
                return false;
            } else {
                setFormData({
                    ...formData,
                    ImagePath: e.target.files[0]
                })
            }
        }
    }


    const onBioChange = (e) => {
        setFormData({
            ...formData,
            Bio: e
        })
    }

    const Reset = () => {
        setFormData({
            ...formData,
            Title: "",
            Description: "",
            Author: "",
            Status: "",
            ImagePath: "",
            EntryID: "",
        })
    }

    return isloading === true ?
        (<Loader />) : (
            <>
                <PageTitle title={["Administartor", "Website News"]} />
                <section className="section">
                    <div className="row align-items-top">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="d-flex justify-content-start">
                                        <h4>Website News</h4>
                                    </div>
                                    <div
                                        className="d-flex justify-content-end"
                                        data-kt-customer-table-toolbar="base"
                                    >
                                        <button data-bs-toggle="modal" data-bs-target="#basicModal"
                                            className="btn btn-sm btn-primary text-white"
                                            onClick={() => {
                                                setFormData({
                                                    ...formData,
                                                    Title: "",
                                                    Description: "",
                                                    Author: "",
                                                    Status: "",
                                                    ImagePath: "",
                                                    EntryID: "",
                                                })
                                            }}
                                        >
                                            <i className="fa fa-plus" /> Add News
                                        </button>
                                    </div>
                                </div>

                                <div className="card-body">
                                    <div className="table table-responsive-lg">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col">S/No</th>
                                                    <th scope="col">Title</th>
                                                    <th scope="col">Author</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col"> Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    newsList.length > 0 ?
                                                        newsList.map((x, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>{i + 1}</td>
                                                                    <td style={{ maxWidth: "450px" }}>{x.Title}</td>
                                                                    <td>{x.Author}</td>
                                                                    <td>{x.Status === 1 ? 'Published' : "Draft"}</td>
                                                                    <td>
                                                                        <button type="button" id="btnModal" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#basicModal" onClick={() => {
                                                                            setFormData({
                                                                                ...formData,
                                                                                Title: x.Title,
                                                                                Description: x.Description,
                                                                                Author: x.Author,
                                                                                Status: x.Status,
                                                                                ImagePath: x.ImagePath,
                                                                                EntryID: x.EntryID
                                                                            })
                                                                        }}> Edit
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                        :
                                                        <tr>
                                                            <td colSpan={7}>No Record</td>
                                                        </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="modal fade" id="basicModal" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content modal-lg">
                            <div className="modal-header">
                                <h5 className="modal-title">Website News</h5>
                                <button type="button" id="btn_close" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body">
                                <form onSubmit={addNews}>
                                    <div className="row">
                                        {formData.EntryID !== "" &&
                                            <div className="text-center mb-2">
                                                <img src={formData.ImagePath === "" || formData.ImagePath === null ? male_avater : `${serverLink}public/uploads/ideas_project/news/${formData.ImagePath}`} alt="Profile" width={700} height={200} />
                                            </div>}
                                        <div className="col-lg-6">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="Title" className="col-md-12 col-lg-12 col-form-label">Title</label>
                                                    <input type="text" className="form-control" id="Title" value={formData.Title} required onChange={onEdit} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="Author" className="col-md-12 col-lg-12 col-form-label">Author</label>
                                                    <input type="text" className="form-control" id="Author" value={formData.Author} onChange={onEdit} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="Description" className="col-md-12 col-lg-12 col-form-label">Description</label>
                                                    <textarea type="text" className="form-control" id="Description" value={formData.Description} onChange={onEdit} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="Status" className="col-md-12 col-lg-12 col-form-label">Status</label>
                                                    <select className="form-control form-select" onChange={onEdit} id="Status" value={formData.Status}>
                                                        <option value={""}>-select Status-</option>
                                                        <option value="1">Publish</option>
                                                        <option value="0">Draft</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="row mb-3">
                                                <div className="col-md-12 col-lg-12">
                                                    <label htmlFor="file" className="col-md-4 col-lg-3 col-form-label">Image Path </label>
                                                    (<small className="text-danger">Max size is 1mb</small>)
                                                    <input type="file" accept="image/*" className="form-control" id="file" onChange={onEdit} />
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className="col-md-12">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="Bio" className="col-md-12 col-lg-12 col-form-label">Bio</label>
                                                    <JoditEditor
                                                        value={formData.Bio}
                                                        ref={editorBio}
                                                        tabIndex={1}
                                                        onChange={onBioChange}
                                                    />
                                                </div>
                                            </div>
                                        </div> */}

                                        <div className="col-md-12">
                                            <div className="row mb-3">
                                                <div className="col-md-12 text-center">
                                                    <button className="btn btn-lg btn-primary col-md-6" type="submit" disabled={loading ? true : false}>
                                                        {
                                                            loading === true &&
                                                            <>
                                                                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" />&emsp;
                                                            </>
                                                        }
                                                        {loading === true ? "Loading..." :
                                                            "Submit"}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(AddNews);
