import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../common/loader";
import PageTitle from "../common/pagetitle/pagetitle";
import { showAlert } from "../common/sweetalert/sweetalert";
import { serverLink } from "../url";
import Select from "react-select";


const EnrolFacilitator = (props) => {
    const [isloading, setIsLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [tutorList, setTutorList] = useState([]);
    const [staffOptions, setStaffOptions] = useState([]);
    const [moduleOptions, setModuleOptions] = useState([]);

    const [formData, setFormData] = useState({
        StaffID: "",
        ModuleID: "",
        Status: "1",
        EntryID: "",
        StaffID2:"",
        ModuleID2:"",
        InsertedBy: props.loginData[0].EmailAddress,
    })
    const getData = async () => {
        await axios.get(`${serverLink}foundation/login/get-tutor-enrol-list`).then((res) => {
            if (res.data.length > 0) {
                setTutorList(res.data)
            }
        })
        setIsLoading(false)
        await axios.get(`${serverLink}foundation/login/get-tutor-list`).then((res) => {
            let rows=[];
            if (res.data.length > 0) {
                res.data.map((row)=>{
                    rows.push({
                        value:row.EntryID, label: row.StaffName
                    })
                })
                setStaffOptions(rows)
            }
        })

        await axios.get(`${serverLink}foundation/login/get-module-list`).then((res) => {
            let rows=[];
            if (res.data.length > 0) {
                res.data.map((row)=>{
                    rows.push({
                        value:row.EntryID, label:`${row.ModuleName} (${row.ModuleCode})`,
                    })
                })
                setModuleOptions(rows)
            }
        })
    }

    useEffect(() => {
        getData();
    }, [])

    const enrolFacilitator = async (e) => {
        e.preventDefault();
        if (formData.StaffID=== "") {
            toast.error("Please enter Title");
            return false;
        }
        setLoading(true);
        try {
            if (formData.EntryID === "") {
                await axios.post(`${serverLink}foundation/login/add/enrol`, formData).then((res) => {
                    if (res.data.message === "success") {
                        setLoading(false);
                        getData();
                        Reset();
                        showAlert("success", "Record added successfully", "success");
                    } else if (res.data.message === "exist") {
                        setLoading(false)
                        showAlert("warning", "Facilitator with this Module already exist", "warning")
                    } else {
                        showAlert("error", "Network error, please check your connection", "error")
                    }
                })
            } else {
                await axios.patch(`${serverLink}foundation/login/update/enrol`, formData).then((res) => {
                    if (res.data.message === "success") {
                        setLoading(false);
                        getData();
                        Reset();
                        showAlert("success", "Record Updated Successfully", "success");
                    }
                })
            }
        } catch (e) {
            console.log(e)
            setLoading(false)
            showAlert("error", "Network Error, try again", "error");
        }

    }

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        })

        if (e.target.id === "file") {
            if (e.target.files[0].size > 1000000) {
                toast.error("max file size is 1mb", "error")
                return false;
            } else {
                setFormData({
                    ...formData,
                    ImagePath: e.target.files[0]
                })
            }
        }
    }

    const Reset = () => {
        setFormData({
            ...formData,
            StaffID: "",
            ModuleID: "",
            Status: "",
            EntryID: "",
            StaffID2:"",
            ModuleID2:"",
        })
    }

    const enrol = async (data) => {
        toast.info('Please Wait...');
        await axios.patch(`${serverLink}foundation/login/update/enrol/${data.EntryID}`)
            .then(data => {
                const result = data.data;
                if (result.message === "success") {
                    toast.success('FACILITATOR ENROLLED SUCCESSFULLY')
                    getData()
                    return false;
                }
            });
    }

    const unEnrol = async (data) => {
        toast.info('Please Wait...');
        await axios.patch(`${serverLink}foundation/login/update/remove/${data.EntryID}`)
            .then(data => {
                const result = data.data;
                if (result.message === "success") {
                    toast.success('FACILITATOR REMOVED SUCCESSFULLY')
                    getData()
                    return false;
                }
            });
    }

    const handleStaffChange = (e) => {
        setFormData({
            ...formData,
            StaffID: e.value,
            StaffID2: e
        })
    }

    const handleModuleChange = (e) => {
        setFormData({
            ...formData,
            ModuleID: e.value,
            ModuleID2: e
        })
    }

    return isloading === true ?
        (<Loader />) : (
            <>
                <PageTitle title={["Administrator", "Enrol Facilitator"]} />
                <section className="section">
                    <div className="row align-items-top">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="d-flex justify-content-start">
                                        <h4>Enrol Facilitator</h4>
                                    </div>
                                    <div>
                                        <form onSubmit={enrolFacilitator}>
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <div className="row mb-3">
                                                        <div className="col-md-12">
                                                            <label htmlFor="StaffID" className="col-md-12 col-lg-12 col-form-label">Staff Name</label>
                                                            <Select
                                                                id="StaffID"
                                                                className="form-select w-100"
                                                                value={formData.StaffID2}
                                                                onChange={handleStaffChange}
                                                                options={staffOptions}
                                                                placeholder="Select Staff"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="row mb-3">
                                                        <div className="col-md-12">
                                                            <label htmlFor="ModuleID" className="col-md-12 col-lg-12 col-form-label">Module Name</label>
                                                            <Select
                                                                id="ModuleID"
                                                                className="form-select w-100"
                                                                value={formData.ModuleID2}
                                                                onChange={handleModuleChange}
                                                                options={moduleOptions}
                                                                placeholder="Select Module"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mt-5">
                                                    <button className="btn btn-lg btn-primary col-md-12" type="submit" disabled={loading ? true : false}>
                                                        {
                                                            loading === true &&
                                                            <>
                                                                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" />&emsp;
                                                            </>
                                                        }
                                                        {loading === true ? "Loading..." :
                                                            "Submit"}
                                                    </button>
                                                </div>

                                            </div>
                                        </form>
                                    </div>
                                </div>

                                <div className="card-body">
                                    <div className="table table-responsive-lg">
                                        <table className="table table-striped">
                                            <thead>
                                            <tr>
                                                <th scope="col">S/No</th>
                                                <th scope="col">Staff Name</th>
                                                <th scope="col">Module Name</th>
                                                <th scope="col">Status</th>
                                                <th scope="col"> Action</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                tutorList.length > 0 ?
                                                    tutorList.map((x, i) => {
                                                        let name, btn = "";
                                                            if (x.Status === 1){
                                                            name = "Added";
                                                            btn =  <button type="button" id="btnRemove" className="btn btn-danger" onClick={() => {unEnrol(x)
                                                            }}>Remove
                                                            </button>
                                                        }else{
                                                                name = "Not Added";
                                                                btn =  <button type="button" id="btnEnrol" className="btn btn-primary" onClick={() => {enrol(x)
                                                                }}>Enrol
                                                                </button>

                                                        }

                                                        return (
                                                            <tr key={i}>
                                                                <td>{i + 1}</td>
                                                                <td style={{ maxWidth: "450px" }}>{x.StaffName}</td>
                                                                <td>{x.ModuleName}</td>
                                                                <td>{name}</td>
                                                                <td>{btn}</td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr>
                                                        <td colSpan={7}>No Record</td>
                                                    </tr>
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </>
        )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(EnrolFacilitator);
