import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import Loader from "../common/loader";
import PageTitle from "../common/pagetitle/pagetitle";
import { bucketUrl, serverLink } from "../url";
import { saveAs } from "file-saver";
import { showAlert } from "../common/sweetalert/sweetalert";
import JoditEditor from "jodit-react";


const ReviewerFeedBack = (props) => {
    const editorRef = React.createRef();

    const [isloading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [documentsList, setdocumentsList] = useState([]);
    const [documentsList2, setdocumentsList2] = useState([])
    const ReviewerID = props.loginData[0].EntryID;

    const [formData, setFormData] = useState({
        DocumentID:"",
        ReviewerID: props.loginData[0].EntryID,
        Status: "1",
        Comment: "",
        FileName:"",
        Score:""
    })

    const getData = async () => {
        try {
            await axios.get(`${serverLink}reviewer/documents/list/${ReviewerID}`).then((res) => {
                if (res.data.length > 0) {
                    setdocumentsList(res.data);
                    setdocumentsList2(res.data)
                }
                setIsLoading(false)
            })

        } catch (e) {
            showAlert("error", "Network error", "error");
        }
    }

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        })
    }

    const SubmitReview = async(e) => {
        e.preventDefault();
        if(formData.Score > 100 ){
            toast.error("score cannnot be more than 100");
            return false
        }
        if(formData.Comment === ""){
            toast.error("comment is required");
            return false;
        }

        try {
            await axios.post(`${serverLink}reviewer/review-document`, formData).then((res)=>{
                if(res.data.message === "success"){
                    getData();
                    toast.success("Review successfully logged");
                    document.getElementById("btn-close").click();
                }
            })
        } catch (e) {
            showAlert("error", "Network error", "error")
        }
    }



    useEffect(() => {
        getData();
    }, [])

    const onSearch = (e) => {
        const filter = documentsList2.filter(x=> x.FileName.toLowerCase().includes(e.target.value.toLowerCase()));
        setdocumentsList(filter);
    }

    const onCommentChange = (e)=>{
        setFormData({
            ...formData,
            Comment: e
        })
    }

    return isloading ? (<Loader />) : (
        <>
            <PageTitle title={["Dashboard", "Review Documents"]} />
            <section className="section">
                <div className="row align-items-top">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="d-flex justify-content-between">
                                    <h5 className="card-title">Review Documents</h5>
                                    <input style={{ height: '30px', borderRadius: '4px' }} onChange={onSearch} placeholder="search" />
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table table-responsive-lg">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">S/No</th>
                                                <th scope="col">Document Name</th>
                                                <th scope="col">Document</th>
                                                <th>Status</th>
                                                <th>Review</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                documentsList.length > 0 ?
                                                    documentsList.map((x, y) => {
                                                        return (
                                                            <tr key={y}>
                                                                <td>{y + 1}</td>
                                                                <td>{x.FileName}</td>
                                                                <td><a 
                                                                href={
                                                                    x.File?.includes("simplefileupload") ? x?.File :
                                                                        `${bucketUrl}${x?.File}`
                                                                } 
                                                                target="_blank"><i className="bi bi-cloud-snow-fill" /></a></td>
                                                                <td>
                                                                    <label className={x.Status === "0" ? "badge bg-danger" : "badge bg-success"}>
                                                                        {
                                                                            x.Status === "0" ? "Not Reviewed" : "Reviewed"
                                                                        }
                                                                    </label>
                                                                </td>
                                                                <td>
                                                                    <button type="button" disabled={x.Status === "1" ? true : false} id="btnModal" className="btn btn-primary ms-3" data-bs-toggle="modal" data-bs-target="#basicModal" onClick={()=>{
                                                                        setFormData({
                                                                            ...formData,
                                                                            DocumentID: x.DocumentID,
                                                                            FileName:x.FileName
                                                                        })
                                                                    }}>
                                                                        <i className="bi bi-check-square-fill" />
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr>
                                                        <td className="alert alert-info">No Documents Assigned to you yet, please check back later</td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="modal fade" id="basicModal" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{formData.FileName}</h5>
                            <button type="button" id="btn-close" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            <form onSubmit={SubmitReview}>
                                <div className="col-md-12">
                                    <div className="row mb-3">
                                        <div className="col-md-12">
                                            <label htmlFor="Score" className="col-md-12 col-lg-12 col-form-label">Scores</label>
                                            <input type="number" max={100} className="form-control" id="Score" value={formData.Score} required onChange={onEdit} />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-md-12">
                                            <label htmlFor="Designation" className="col-md-12 col-lg-12 col-form-label">Comment</label>
                                            <JoditEditor
                                                value={formData.Comment}
                                                ref={editorRef}
                                                tabIndex={1}
                                                onChange={onCommentChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-md-12">
                                            <button type="submit" className="btn btn-primary">Submit Review</button>

                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(ReviewerFeedBack);