import axios from "axios";
import React, {useEffect, useRef, useState} from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import Loader from "../common/loader";
import PageTitle from "../common/pagetitle/pagetitle";
import { serverLink } from "../url";
import { saveAs } from "file-saver";
import { formatDateAndTime } from "../resources/constants";
import {useReactToPrint} from "react-to-print";
import {Image} from "@material-ui/icons";
import classes from './certicate.css';

const Participants = (props) => {
    const componentRef = useRef();
    const [isloading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [participantsList, setparticipantsList] = useState([]);
    const [attendanceList, setAttendanceList] = useState([])
    const [selectedParticipant, setSelectedParticipant] = useState([])
    const [eventsList, setEventsList] = useState([])
    const [renderCertificate, setRenderCertificate] = useState(false)
    const [canPrintCertificate, setCanPrintCertificate] = useState(false)

    const Email = props.loginData[0].Email
    const ParticipantID = props.loginData[0].EntryID

    const printCertificate = useReactToPrint({
        content: () => componentRef.current,
    })


    // const printParticipantCertificate = (id) => {
    //     if (participantsList.length > 0){
    //         const filteredParticipant = participantsList.filter(i => i.EntryID === Number(id));
    //         setSelectedParticipant(filteredParticipant)
    //         setRenderCertificate(true)
    //         setCanPrintCertificate(true)
    //     }
    // }

    const getData = async () => {
        try {
            await axios.post(`${serverLink}participants/participant/fetch`, { Email: Email }).then((res) => {
                if (res.data.length > 0) {
                    setparticipantsList(res.data);
                }
                //setIsLoading(false)
            })

        } catch (e) {

        }

        try {
            await axios.post(`${serverLink}participants/participant/attendance`, { ParticipantID: ParticipantID }).then((res) => {
                if (res.data.length > 0) {
                    setAttendanceList(res.data);
                }
                setIsLoading(false)
            })

        } catch (e) {

        }

        try {
            await axios.get(`${serverLink}events/list/event/Active`).then((res) => {
                if (res.data.length > 0) {
                    setEventsList(res.data);
                }
                setIsLoading(false)
            })

        } catch (e) {

        }
    }


    const generateCertificate = async () => {
        const sendData = {
            participantInfo: {
                fullName: props.loginData[0].FirstName + "" + props.loginData[0].MiddleName + "  " + props.loginData[0].LastName
            },
            eventInfo:{
                eventData: eventsList[0],
                endDate: formatDateAndTime(eventsList[0].EndDate, "date"),
                startDate: formatDateAndTime(eventsList[0].StartDate, "date")

            }
        }
        await axios.post(`${serverLink}certificate/create-certificate-pdf`, sendData)
            .then(async (result) => {
                if (result.data.message === "success") {
                    await axios.post(`${serverLink}certificate/fetch-certificate-pdf`, { filename: result.data.filename }, { responseType: 'blob' })
                        .then((res) => {
                            const pdfBlob = new Blob([res.data], { type: 'application/pdf' });
                            saveAs(pdfBlob, `${props.loginData[0].FirstName + "_" + props.loginData[0].MiddleName + "_" + props.loginData[0].LastName}.pdf`)
                        })
                }
            })
    }

    useEffect(() => {
        getData().then(r => {});
    }, [])


    return isloading ? (<Loader />) : (
        <>
            <PageTitle title={["Dashboard", "Certificate"]} />
            <section className="section">
                <div className="row align-items-top">
                    <div className="col-lg-12">
                        <div className="card">
                            {
                                attendanceList.length > 0 ?

                                    <div className="card-body">
                                        <h5 className="card-title">My Information</h5>
                                        <div className="table table-responsive-lg">
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">S/No</th>
                                                        <th scope="col">Full Name</th>
                                                        <th scope="col">Email Address</th>
                                                        <th scope="col">Organization</th>
                                                        <th scope="col">Participation Type</th>
                                                        <th scope="col">Certificate</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        participantsList.length > 0 ?
                                                            participantsList.map((x, i) => {
                                                                return (
                                                                    <tr key={i}>
                                                                        <td>{i + 1}</td>
                                                                        <td>
                                                                            {x.FirstName + " " + x.MiddleName + " " + x.LastName}
                                                                        </td>
                                                                        <td>{x.Email}</td>
                                                                        <td>{x.Organization}</td>
                                                                        <td>{x.ParticipantType}</td>
                                                                        <td><button onClick={() => {
                                                                            toast.info('Please wait system processing, this might take sometime....')
                                                                            generateCertificate(x.EntryID).then(r => {})
                                                                            // generateCertificate(x)
                                                                        }} className="btn btn-success btn-sm">Download</button></td>
                                                                    </tr>
                                                                )
                                                            })
                                                            :
                                                            <tr>
                                                                <td colSpan={7}>No session</td>
                                                            </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    : <div className="col-md-12 text-center alert alert-danger">You don't have Certificate for this event</div>
                            }
                        </div>
                    </div>

                    {/*{selectedParticipant.length > 0 && renderCertificate === true  && (*/}
                    {/*    <div ref={componentRef}>*/}
                    {/*        <div>*/}
                    {/*            <div className="content-holder col-md-3 pt-5">*/}
                    {/*                <div className="padding">*/}
                    {/*                    <div className="front">*/}
                    {/*                        <div className="top">*/}
                    {/*                            <p className="top-text">ITRI WEEK 2022</p>*/}
                    {/*                        </div>*/}
                    {/*                        <div className="bottom">*/}
                    {/*                            <p>*/}
                    {/*                                {selectedParticipant[0].FirstName}{" "}*/}
                    {/*                                {selectedParticipant[0].MiddleName}{" "}*/}
                    {/*                                {selectedParticipant[0].LastName} < br/>*/}
                    {/*                                <h6>({selectedParticipant[0].ParticipantType})</h6>*/}
                    {/*                            </p>*/}
                    {/*                            <br />*/}
                    {/*                            <div className="barcode">*/}
                    {/*                                <Image*/}
                    {/*                                    text={selectedParticipant[0].Email}*/}
                    {/*                                    options={{*/}
                    {/*                                        type: 'image/jpeg',*/}
                    {/*                                        quality: 0.3,*/}
                    {/*                                        level: 'M',*/}
                    {/*                                        margin: 3,*/}
                    {/*                                        scale: 4,*/}
                    {/*                                        width: 200,*/}
                    {/*                                        color: {*/}
                    {/*                                            dark: '#010599FF',*/}
                    {/*                                            light: '#FFBF60FF',*/}
                    {/*                                        },*/}
                    {/*                                    }}*/}
                    {/*                                />*/}
                    {/*                            </div>*/}
                    {/*                            <div className="staff-type p-3">*/}
                    {/*                                {selectedParticipant[0].Organization}{" "}*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}

                    {/*            </div>*/}
                    {/*            <br />*/}
                    {/*            <br />*/}
                    {/*            <br />*/}
                    {/*            <br />*/}
                    {/*            <br />*/}
                    {/*            <br />*/}
                    {/*            <br />*/}
                    {/*            <br />*/}
                    {/*            <br />*/}
                    {/*            <div className="pagebreak"></div>*/}

                    {/*            <br />*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*)}*/}

                </div>
            </section>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(Participants);
