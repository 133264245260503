import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../common/loader";
import PageTitle from "../common/pagetitle/pagetitle";
import { showAlert } from "../common/sweetalert/sweetalert";
import { serverLink } from "../url";
import JoditEditor from "jodit-react";
import  Select from 'react-select'

const AddModule = (props) => {
    const editorBio = React.createRef();
    const [isloading, setIsLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [moduleList, setModuleList] = useState([]);
    const [courseList, setCourseList] = useState([]);
    const [courseOptions, setCourseOptions] = useState([]);


    const [formData, setFormData] = useState({
        CourseID: "",
        ModuleCode: "",
        Description: "",
        CreditUnit: "",
        EntryID: "",
        CourseID2:"",
        InsertedBy: props.loginData[0].EmailAddress,
    })
    const getData = async () => {
        await axios.get(`${serverLink}foundation/login/get-module-list`).then((res) => {
            if (res.data.length > 0) {
                setModuleList(res.data)
            }
        })
        setIsLoading(false)

        await axios.get(`${serverLink}foundation/login/get-course-list`).then((res) => {
            let rows=[];
            if (res.data.length > 0) {
                res.data.map((row)=>{
                    rows.push({
                        value:row.EntryID, label: row.CourseName
                    })
                })
                setCourseList(res.data)
                setCourseOptions(rows)
            }
        })
    }

    useEffect(() => {
        getData();
    }, [])

    const addModule = async (e) => {
        e.preventDefault();
        if (formData.CourseID === "") {
            toast.error("Please enter CourseID");
            return false;
        }
        setLoading(true);
        try {
            if (formData.EntryID === "") {
                await axios.post(`${serverLink}foundation/login/add/module`, formData).then((res) => {
                    if (res.data.message === "success") {
                        setLoading(false);
                        getData();
                        Reset();
                        showAlert("success", "Record added successfully", "success");
                        document.getElementById("btn_close").click();
                    } else if (res.data.message === "exist") {
                        setLoading(false)
                        showAlert("warning", "Module code already exist", "warning")
                    } else {
                        showAlert("error", "Network error, please check your connection", "error")
                    }
                })
            } else {
                await axios.patch(`${serverLink}foundation/login/update/module`, formData).then((res) => {
                    if (res.data.message === "success") {
                        setLoading(false);
                        getData();
                        Reset();
                        showAlert("success", "Record Updated Successfully", "success");
                        document.getElementById("btn_close").click();
                    }
                })
            }
        } catch (e) {
            console.log(e)
            setLoading(false)
            showAlert("error", "Network Error, try again", "error");
        }

    }

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        })
    }

    const handleCourseChange = (e) => {
        setFormData({
            ...formData,
            CourseID: e.value,
            CourseID2: e
        })
    }

    const onBioChange = (e) => {
        setFormData({
            ...formData,
            Description: e
        })
    }

    const Reset = () => {
        setFormData({
            ...formData,
            CourseID: "",
            ModuleCode: "",
            Description: "",
            CreditUnit: "",
            EntryID: "",
        })
    }

    return isloading === true ?
        (<Loader />) : (
            <>
                <PageTitle title={["Administrator", "Add Module"]} />
                <section className="section">
                    <div className="row align-items-top">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="d-flex justify-content-start">
                                        <h4>Add Module</h4>
                                    </div>
                                    <div
                                        className="d-flex justify-content-end"
                                        data-kt-customer-table-toolbar="base"
                                    >
                                        <button data-bs-toggle="modal" data-bs-target="#basicModal"
                                                className="btn btn-sm btn-primary text-white"
                                                onClick={() => {
                                                    setFormData({
                                                        ...formData,
                                                        CourseID: "",
                                                        ModuleCode: "",
                                                        Description: "",
                                                        CreditUnit: "",
                                                        EntryID: "",
                                                        CourseID2:"",
                                                        ModuleName:""
                                                    })
                                                }}
                                        >
                                            <i className="fa fa-plus" /> Add Module
                                        </button>
                                    </div>
                                </div>

                                <div className="card-body">
                                    <div className="table table-responsive-lg">
                                        <table className="table table-striped">
                                            <thead>
                                            <tr>
                                                <th scope="col">S/No</th>
                                                <th scope="col">Module Code</th>
                                                <th scope="col">Module Name</th>
                                                <th scope="col">Credit Unit</th>
                                                <th scope="col"> Action</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                moduleList.length > 0 ?
                                                    moduleList.map((x, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>{i + 1}</td>
                                                                <td style={{ maxWidth: "450px" }}>{x.ModuleCode}</td>
                                                                <td>{x.ModuleName}</td>
                                                                <td>{x.CreditUnit}</td>
                                                                <td>
                                                                    <button type="button" id="btnModal" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#basicModal" onClick={() => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            CourseID: x.CourseID,
                                                                            CourseID2: { value: x.CourseID, label: x.CourseName },
                                                                            ModuleCode: x.ModuleCode,
                                                                            ModuleName: x.ModuleName,
                                                                            Description: x.Description,
                                                                            CreditUnit: x.CreditUnit,
                                                                            EntryID: x.EntryID
                                                                        })
                                                                    }}> Edit
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr>
                                                        <td colSpan={7}>No Record</td>
                                                    </tr>
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="modal fade" id="basicModal" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content modal-lg">
                            <div className="modal-header">
                                <h5 className="modal-title">Add Module</h5>
                                <button type="button" id="btn_close" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body">
                                <form onSubmit={addModule}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="CourseID" className="col-md-12 col-lg-12 col-form-label">Course Name</label>
                                                    <Select
                                                        id="CourseID"
                                                        className="form-select w-100"
                                                        value={formData.CourseID2}
                                                        onChange={handleCourseChange}
                                                        options={courseOptions}
                                                        placeholder="Select Course"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="CreditUnit" className="col-md-12 col-lg-12 col-form-label">Credit Unit</label>
                                                    <input type="text" className="form-control" id="CreditUnit" value={formData.CreditUnit} onChange={onEdit} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="ModuleCode" className="col-md-12 col-lg-12 col-form-label">Module Code</label>
                                                    <input type="text" className="form-control" id="ModuleCode" value={formData.ModuleCode} onChange={onEdit} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="ModuleName" className="col-md-12 col-lg-12 col-form-label">Module Name</label>
                                                    <input type="text" className="form-control" id="ModuleName" value={formData.ModuleName} onChange={onEdit} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="Description" className="col-md-12 col-lg-12 col-form-label">Description</label>
                                                    <JoditEditor
                                                        value={formData.Description}
                                                        ref={editorBio}
                                                        tabIndex={1}
                                                        onChange={onBioChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="row mb-3">
                                                <div className="col-md-12 text-center">
                                                    <button className="btn btn-lg btn-primary col-md-6" type="submit" disabled={loading ? true : false}>
                                                        {
                                                            loading === true &&
                                                            <>
                                                                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" />&emsp;
                                                            </>
                                                        }
                                                        {loading === true ? "Loading..." :
                                                            "Submit"}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(AddModule);
