import axios from "axios";
import React, { useState, useRef, createRef, useEffect } from "react";
import { connect } from "react-redux";
import Loader from "../../common/loader";
import PageTitle from "../../common/pagetitle/pagetitle";
import { serverLink } from "../../url";
import { useQRCode } from 'next-qrcode';
import {useReactToPrint} from "react-to-print";
import classes from './generate-tag.css';
const JsBarcode = require('jsbarcode');



function GenerateTag() {
    const [isLoading, setIsLoading] = useState(false);
    const { Image } = useQRCode();
    const componentRef = useRef();
    const [participantList, setParticipantList] = useState([]);
    const [selectedParticipant, setSelectedParticipant] = useState([])
    const [selectedParticipants, setSelectedParticipants] = useState([])
    const [renderIDCard, setRenderIDCard] = useState(false)
    const [canPrintIDCard, setCanPrintIDCard] = useState(false)
    const [createRequest, setCreateRequest] = useState({
        UserID: "",
        CardType: "",
    });
    const wrapper = createRef();


    const onEditParticipantsList = (e) => {
        const id = e.target.id;
        const value = e.target.value;

        setCreateRequest({
            ...createRequest,
            [id]: value,
        });
    };

    const onEdit = (e) => {
        setCreateRequest({
            ...createRequest,
            [e.target.id]: e.target.value,
        });

        if (e.target.value === "All Participant"){
            axios.get(`${serverLink}tag/get/participants`)
                .then((res) => {
                    if (res.data > 0 ){
                        setParticipantList(res.data)
                    }
                })
                .catch((err) => {
                    console.log("NETWORK ERROR");
                });
        }

        if (e.target.value === "Single Participant"){
            axios.get(`${serverLink}tag/get/participants`)
                .then((res) => {
                    if (res.data > 0 ){
                        setParticipantList(res.data)
                    }
                })
                .catch((err) => {
                    console.log("NETWORK ERROR");
                });
        }
    }

    const onGenerate = async () => {
        if (createRequest.CardType === "All Participant"){
            axios.get(`${serverLink}tag/get/participants`)
                .then((res) => {
                    if (res.data.length > 0 ){
                        setParticipantList(res.data)
                        setRenderIDCard(true);
                        setCanPrintIDCard(true);
                    }
                })
                .catch((err) => {
                    console.log("NETWORK ERROR");
                });
        }
        if (createRequest.CardType === "Single Participant") {

            if (createRequest.UserID !== ""){
                console.log("createRequest.UserID ", createRequest.UserID )
                const filteredParticipant = selectedParticipants.filter(i => i.EntryID === Number(createRequest.UserID));

                console.log("participant", filteredParticipant)
                console.log("selectedParticipants", selectedParticipants)

                setSelectedParticipant(filteredParticipant)
                setRenderIDCard(true)
                setCanPrintIDCard(true)
            }
        }
    }

    const printIDCard = useReactToPrint({
        content: () => componentRef.current,
    })

    useEffect( () => {
            axios.get(`${serverLink}tag/get/participants`)
                .then((res) => {
                    if (res.data.length > 0 ){
                        setSelectedParticipants(res.data)
                    }
                })
                .catch((err) => {
                    console.log("NETWORK ERROR");
                });
    }, [])

    return isLoading ? <Loader/> : (
        <div className="d-flex flex-column flex-row-fluid">
            <PageTitle title={["Administrator", "Generate Tag"]} />
            <div className="flex-column-fluid h-500px">
                <div className="card">
                    <div className="card-body pt-0 h-500px">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-3">

                                    <div className="col-md-12 pt-3">
                                        <label htmlFor="CardType">Select Tag Type</label>
                                        <select
                                            className="form-control"
                                            id="CardType"
                                            name="CardType"
                                            value={createRequest.CardType}
                                            onChange={onEdit}
                                        >
                                            <option value="">Select Option</option>
                                            <option value="All Participant">All Participant</option>
                                            <option value="Single Participant">Single Participant</option>
                                        </select>
                                    </div>


                                    {createRequest.CardType === "Single Participant" &&  (
                                        <div className="col-md-12 pt-3">
                                            <div className="form-group">
                                                <label htmlFor="UserID">Select Participant</label>
                                                <select
                                                    id="UserID"
                                                    name="UserID"
                                                    value={createRequest.UserID}
                                                    className="form-control"
                                                    onChange={onEditParticipantsList}
                                                >
                                                    <option value="">Select Option</option>
                                                    {selectedParticipants.length > 0 ? (
                                                        <>
                                                            {selectedParticipants.map((item, index) => {
                                                                return (
                                                                    <option key={index} value={item.EntryID}>
                                                                        {item.FirstName}{" "}{item.MiddleName}{" "}{item.LastName}
                                                                    </option>
                                                                );
                                                            })}
                                                        </>
                                                    ) : (
                                                        ""
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                    )}


                                    {createRequest.CardType === "All Participant" &&  (
                                        <div className="pt-2"> <button className="btn btn-primary w-100" onClick={onGenerate}>Generate</button></div>
                                    )}

                                    {createRequest.CardType === "Single Participant"
                                    && createRequest.UserID !== "" &&
                                    createRequest.UserID !== "undefined"
                                    &&  (
                                        <div className="pt-2"> <button className="btn btn-primary w-100" onClick={onGenerate}>Generate</button></div>
                                    )}

                                    {participantList.length > 0 && createRequest.CardType === "All Participant" && renderIDCard === true &&
                                    canPrintIDCard === true
                                        && (
                                        <div className="pt-3"> <button className="btn btn-primary w-100" onClick={printIDCard}>Print</button></div>
                                    )}

                                    {createRequest.CardType === "Single Participant"
                                        && createRequest.UserID !== "" &&
                                        createRequest.UserID !== "undefined"
                                        &&  renderIDCard === true && (
                                            <div className="pt-3"> <button className="btn btn-primary w-100" onClick={printIDCard}>Print</button></div>
                                        )}

                                    {/*PRINT ALL TAG*/}
                                    {participantList.length > 0 && createRequest.CardType === "All Participant" && renderIDCard === true && (
                                        <div ref={componentRef}>
                                            {participantList.map((item, index) => {
                                                return (
                                                    <div>
                                                        <div className="content-holder col-md-3 pt-5">
                                                            <div className="padding">
                                                                <div className="front">
                                                                    <div className="top">
                                                                        <p className="top-text">ITRI WEEK 2022</p>
                                                                    </div>
                                                                    <div className="bottom">
                                                                        <p>
                                                                            {item.FirstName}{" "}
                                                                            {item.MiddleName}{" "}
                                                                            {item.LastName} < br/>
                                                                            <h6>({item.ParticipantType})</h6>
                                                                        </p>
                                                                        <br />
                                                                        <div className="barcode">
                                                                            <Image
                                                                                text={item.Email}
                                                                                options={{
                                                                                    type: 'image/jpeg',
                                                                                    quality: 0.3,
                                                                                    level: 'M',
                                                                                    margin: 3,
                                                                                    scale: 4,
                                                                                    width: 200,
                                                                                    color: {
                                                                                        dark: '#010599FF',
                                                                                        light: '#FFBF60FF',
                                                                                    },
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div className="staff-type p-3">
                                                                            {item.Organization}{" "}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <br />
                                                        <br />
                                                        <br />
                                                        <br />
                                                        <br />
                                                        <br />
                                                        <br />
                                                        <br />
                                                        <br />
                                                        <div className="pagebreak"></div>

                                                        <br />
                                                    </div>

                                                );
                                            })}
                                        </div>
                                    )}

                                    {/*PRINT SINGLE TAG*/}
                                    {createRequest.CardType === "Single Participant" && selectedParticipant.length > 0
                                        && createRequest.UserID !== "" &&
                                        createRequest.UserID !== "undefined"
                                        &&  renderIDCard === true && (
                                        <div ref={componentRef}>
                                            <div>
                                                <div className="content-holder col-md-3 pt-5">
                                                    <div className="padding">
                                                        <div className="front">
                                                            <div className="top">
                                                                <p className="top-text">ITRI WEEK 2022</p>
                                                            </div>
                                                            <div className="bottom">
                                                                <p>
                                                                    {selectedParticipant[0].FirstName}{" "}
                                                                    {selectedParticipant[0].MiddleName}{" "}
                                                                    {selectedParticipant[0].LastName} < br/>
                                                                    <h6>({selectedParticipant[0].ParticipantType})</h6>
                                                                </p>
                                                                <br />
                                                                <div className="barcode">
                                                                    <Image
                                                                        text={selectedParticipant[0].Email}
                                                                        options={{
                                                                            type: 'image/jpeg',
                                                                            quality: 0.3,
                                                                            level: 'M',
                                                                            margin: 3,
                                                                            scale: 4,
                                                                            width: 200,
                                                                            color: {
                                                                                dark: '#010599FF',
                                                                                light: '#FFBF60FF',
                                                                            },
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="staff-type p-3">
                                                                    {selectedParticipant[0].Organization}{" "}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <br />
                                                <br />
                                                <br />
                                                <br />
                                                <br />
                                                <br />
                                                <br />
                                                <br />
                                                <br />
                                                <div className="pagebreak"></div>

                                                <br />
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )

}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(GenerateTag);
