import React from "react";
import {Route, Routes} from 'react-router-dom'
import Login from "../auth/login";
import NotFound404 from "../error-pages/notfound404";

const PubLicRoutes=(props)=>{

    return(
        <Routes>
        <Route  path={"/"} element={<Login/>}  />
        <Route  path={"/login"} element={<Login/>}  />
        <Route path="*" element={<NotFound404/>} />
        <Route path="*" element={<Login/>} />
    </Routes>
    )
}
export default PubLicRoutes;