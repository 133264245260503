import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../common/loader";
import PageTitle from "../common/pagetitle/pagetitle";
import { showAlert } from "../common/sweetalert/sweetalert";
import { serverLink, encryptData } from "../url";


const AddUsers = (props) => {
    const [isloading, setIsLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const [formData, setFormData] = useState({
        FirstName: "",
        EmailAddress: "",
        OtherNames: "",
        Role: "",
        Password: encryptData('123456789'),
        InsertedBy: props.loginData[0].EmailAddress,
        EntryID: "",
    })
    const getData = async () => {
        await axios.get(`${serverLink}foundation/login/users/list`).then((res) => {
            if (res.data.length > 0) {
                setData(res.data)
            }
        })
        setIsLoading(false)
    }

    useEffect(() => {
        getData();
    }, [])

    const enrolUser = async (e) => {
        e.preventDefault();
        if (formData.FirstName.trim() === "") {
            toast.error("Please enter the First Name");
            return false;
        }
        setLoading(true);
        try {
            if (formData.EntryID === "") {
                await axios.post(`${serverLink}foundation/login/add/user`, formData).then((res) => {
                    if (res.data.message === "success") {
                        setLoading(false);
                        getData();
                        Reset();
                        showAlert("success", "Record added successfully", "success");
                        document.getElementById("btn_close").click();
                    } else if (res.data.message === "exist") {
                        setLoading(false)
                        showAlert("warning", "User with this email address already exist", "warning")
                    } else {
                        showAlert("error", "Network error, please check your connection", "error")
                    }
                })
            } else {
                await axios.patch(`${serverLink}foundation/login/update/user/${formData.EntryID}`, formData).then((res) => {
                    if (res.data.message === "success") {
                        setLoading(false);
                        getData();
                        Reset();
                        showAlert("success", "Record Updated Successfully", "success");
                        document.getElementById("btn_close").click();
                    }
                })
            }
        } catch (e) {
            console.log(e)
            setLoading(false)
            showAlert("error", "Network Error, try again", "error");
        }

    }

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        })
    }

    const Reset = () => {
        setFormData({
            ...formData,
            FirstName: "",
            EmailAddress: "",
            OtherNames: "",
            Role: "",
            EntryID: "",
        })
    }

    return isloading === true ?
        (<Loader />) : (
            <>
                <PageTitle title={["Administartor", "Enrol User"]} />
                <section className="section">
                    <div className="row align-items-top">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="d-flex justify-content-start">
                                        <h4>Enrol User</h4>
                                    </div>
                                    <div
                                        className="d-flex justify-content-end"
                                        data-kt-customer-table-toolbar="base"
                                    >
                                        <button data-bs-toggle="modal" data-bs-target="#basicModal"
                                            className="btn btn-sm btn-primary text-white"
                                            onClick={() => {
                                                setFormData({
                                                    ...formData,
                                                    FirstName: "",
                                                    EmailAddress: "",
                                                    OtherNames: "",
                                                    Role: "",
                                                    EntryID: "",
                                                })
                                            }}
                                        >
                                            <i className="fa fa-plus" /> Enrol User
                                        </button>
                                    </div>
                                </div>

                                <div className="card-body">
                                    <div className="table table-responsive-lg">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Full Name</th>
                                                    <th scope="col">Email Address</th>
                                                    <th scope="col">Role</th>
                                                    <th scope="col"> Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    data.length > 0 ?
                                                        data.map((x, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>{i + 1}</td>
                                                                    <td style={{ maxWidth: "450px" }}>{x.FirstName + " " + x.OtherNames}</td>
                                                                    <td>{x.EmailAddress}</td>
                                                                    <td>{x.Role}</td>
                                                                    <td>
                                                                        <button type="button" id="btnModal" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#basicModal" onClick={() => {
                                                                            setFormData({
                                                                                ...formData,
                                                                                FirstName: x.FirstName,
                                                                                EmailAddress: x.EmailAddress,
                                                                                OtherNames: x.OtherNames,
                                                                                Role: x.Role,
                                                                                Password: encryptData('123456789'),
                                                                                EntryID: x.EntryID,
                                                                            })
                                                                        }}> Edit
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                        :
                                                        <tr>
                                                            <td colSpan={7}>No Record</td>
                                                        </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="modal fade" id="basicModal" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content modal-lg">
                            <div className="modal-header">
                                <h5 className="modal-title">Enrol User</h5>
                                <button type="button" id="btn_close" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body">
                                <form onSubmit={enrolUser}>
                                    <div className="row">
                                    
                                        <div className="col-lg-6">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="FirstName" className="col-md-12 col-lg-12 col-form-label">First Name</label>
                                                    <input type="text" className="form-control" id="FirstName" value={formData.FirstName} required onChange={onEdit} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="OtherNames" className="col-md-12 col-lg-12 col-form-label">Other Names</label>
                                                    <input type="text" className="form-control" id="OtherNames" value={formData.OtherNames} onChange={onEdit} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="EmailAddress" className="col-md-12 col-lg-12 col-form-label">Email Address</label>
                                                    <input type="text" className="form-control" id="EmailAddress" value={formData.EmailAddress} onChange={onEdit} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="Role" className="col-md-12 col-lg-12 col-form-label">Role</label>
                                                    <select className="form-control form-select" onChange={onEdit} id="Role" value={formData.Role}>
                                                        <option value={""}>-select Role-</option>
                                                        <option value="Admin">Admin</option>
                                                        <option value="User">User</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="row mb-3">
                                                <div className="col-md-12 text-center">
                                                    <button className="btn btn-lg btn-primary col-md-6" type="submit" disabled={loading ? true : false}>
                                                        {
                                                            loading === true &&
                                                            <>
                                                                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" />&emsp;
                                                            </>
                                                        }
                                                        {loading === true ? "Loading..." :
                                                            "Submit"}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(AddUsers);
