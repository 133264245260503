import { UploadFile } from "@mui/icons-material";
import React from "react";
import { useState } from "react";
import { Route, Routes } from 'react-router-dom'
import Login from "../auth/login";
import Header from "../common/header/header";
import SideBar from "../common/sidebar/sidebar";
import DashBoard from "../dashboard/dashboard";
import NotFound404 from "../error-pages/notfound404";
import Notices from "../profile/notices";
import Profile from "../profile/profile";
import DocumentUploads from "../profile/uploads";
import GenerateTag from "../admin/generate-tag/generate-tag";
import ReviewerFeedBack from "../reviewer/review";
import Certificate from "../certificate/certificate";
import Papers from "../admin/papers/papers";
import AddImplementationTeam from "../admin/add-implementation-team";
import NewApplications from "../dashboard/new-applications";
import AllApplications from "../dashboard/all-applications";
import RejectedApplications from "../dashboard/rejected-applications";
import ApprovedApplications from "../dashboard/approved-applications";
import AddUsers from "../admin/add-users";
import AddSlider from "../admin/add-slider";
import AddNews from "../admin/add-news";
import Reports from "../reports/reports";
import AddFacilitator from "../admin/add-facilitator";
import AddModule from "../admin/add-module";
import EnrolFacilitator from "../admin/enrol-facilitator";
import AddCourse from "../admin/add-course";
import ReportByState from "../reports/report-by-state";
import ApplicantValidator from "../application-process/applicant-bvn-validator";
import ReportByStateVerified from "../reports/report-by-state-verified";
import ApplicantRemainder from "../notification/remainder";
import ApplicantExtension from "../notification/extension";
import ResultNotification from "../results-notification/results-notification";


const PageRoutes = (props) => {
    const [toggle, setToggle] = useState("")

    const toggleSidebar = () => {
        const tg = toggle === "" ? "toggle-sidebar" : ""
        setToggle(tg)
    }

    return (
        <div className={toggle} >
            <Header toggleSidebar={toggleSidebar} />
            <SideBar />
            <main id="main" className="main">
                <Routes>
                    <Route path={"/login"} element={<DashBoard />} />
                    <Route path={"/dashboard"} element={<DashBoard />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/uploads" element={<DocumentUploads />} />
                    <Route path="/notice" element={<Notices />} />
                    <Route path="/certificate" element={<Certificate />} />
                    <Route path="/review" element={<ReviewerFeedBack />} />

                    {/* admin */}
                    {/* applications AND sYSTEM MANAGEMENT*/}
                    <Route path="/admin/new-applications" element={<NewApplications />} />
                    <Route path="/admin/all-applications" element={<AllApplications />} />
                    <Route path="/admin/approved-applications" element={<ApprovedApplications />} />
                    <Route path="/admin/rejected-applications" element={<RejectedApplications />} />
                    <Route path="/admin/add-users" element={<AddUsers />} />
                    <Route path="/admin/add-slider" element={<AddSlider />} />
                    <Route path="/admin/add-news" element={<AddNews />} />
                    <Route path="/admin/implementation-team" element={<AddImplementationTeam />} />
                    <Route path="/admin/add-facilitator" element={<AddFacilitator />} />
                    <Route path="/admin/add-module" element={<AddModule />} />
                    <Route path="/admin/enrol-facilitator" element={<EnrolFacilitator />} />
                    <Route path="/admin/add-course" element={<AddCourse />} />
                    <Route path="/admin/validation" element={<ApplicantValidator />} />
                    <Route path="/admin/remainder" element={<ApplicantRemainder />} />
                    <Route path="/admin/extension" element={<ApplicantExtension />} />
                    <Route path="/admin/notify" element={<ResultNotification />} />

                    {/* REPORTS*/}
                    <Route path="/admin/reports" element={<Reports />} />
                    <Route path="/admin/reports-by-state" element={<ReportByState />} />
                    <Route path="/admin/reports-by-state-verified" element={<ReportByStateVerified />} />


                    {/* <Route  path={"/login"} element={<Login/>}  /> */}
                    <Route path="*" element={<DashBoard />} />
                </Routes>
            </main>

        </div>
    )
}
export default PageRoutes;
