import React from 'react'
import Loader from "../common/loader";
import PageTitle from '../common/pagetitle/pagetitle';
import axios from 'axios';
import { useEffect } from 'react';
import { serverLink } from '../url';
import { useState } from 'react';
import ReportTable from "../common/table/report_table";
import { toast } from "react-toastify";
import { connect } from 'react-redux';

const RejectedApplications = (props) => {
    const editorBio = React.createRef();
    const [isloading, setIsLoading] = useState(true);
    const column = ["S/N", "APP ID", "FULL Name", "EMAIL ADDRESS", "Area Of Training", "Is Disabled", "Course Name", "Highest Qualification", "Training Mode"];
    const [data, setData] = useState([]);
    const [tableHeight, setTableHeight] = useState("600px");

    const getData = async () => {
        await axios.get(`${serverLink}foundation/dashboard/application/reject`).then((res) => {
            const result = res.data;
            if (result.length > 0) {
                let rows = [];
                result.map((applicant, index) => {
                    rows.push([
                        index + 1,
                        applicant.EntryID,
                        applicant.FullName,
                        applicant.EmailAddress,
                        applicant.AreaOfTraining,
                        applicant.IsDisabled === 1 ? 'Yes' :'No',
                        applicant.CourseName,
                        applicant.HighestQualification,
                        applicant.TrainingMode,
                    ]);
                });
                setTableHeight(result.length > 100 ? "1000px" : "600px");
                setData(rows);
            } else {
                toast.error("There are no rejected application available");
            }
            setIsLoading(false);
        })
            .catch((err) => {
                toast.error("NETWORK ERROR");
            });
    }

    useEffect(() => {
        getData();
    }, [])


    return isloading === true ?
        (<Loader />) : (
            <>
                <PageTitle title={["Administartor", "Rejected Applications"]} />

                <ReportTable title={`Rejected Applications`} columns={column} data={data} height={tableHeight}
                />
            </>
        )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(RejectedApplications);
