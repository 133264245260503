import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Loader from "../../common/loader";
import PageTitle from "../../common/pagetitle/pagetitle";
import { bucketUrl, sendMail, serverLink } from "../../url";


const Papers = (props) => {
    const [isloading, setIsloading] = useState(true);
    const [files, setFiles] = useState([]);
    const [speakers, setSpeakers] = useState([]);

    const getSpeakers = async () => {
        await axios.get(`${serverLink}speakers/admin-speakers/list`).then((res) => {
            if (res.data.length > 0) {
                setSpeakers(res.data);
            }
        })
    }

    const getData = async () => {
        await axios.get(`${serverLink}conference/dashboard/all/papers`)
            .then((res) => {
                if (res.data.length > 0) {
                    setFiles(res.data)
                }
                setIsloading(false)
            })
    }

    useEffect(() => {
        getData().then(r => { });
        getSpeakers().then(r => { });
    }, [])
    return isloading ? (<Loader />) : (
        <>
            <PageTitle title={["Home", "All Paper"]} />
            <section className="section">
                <div className="row align-items-top">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">All Papers</h5>

                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">S/N</th>
                                            <th scope="col">Paper Title</th>
                                            <th scope="col">Author</th>
                                            <th scope="col">File</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            files.length > 0 ?
                                                files.map((x, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <th scope="row">{i + 1}</th>
                                                            <td>{x.FileName}</td>
                                                            <td className="ps-0">
                                                                {speakers.length > 0 &&
                                                                    speakers.filter(
                                                                        (i) =>
                                                                            i.EntryID === x.SpeakerID
                                                                    )
                                                                        .map(
                                                                            (r) => r.FirstName + " " + r.MiddleName + " " + r.MiddleName
                                                                        )}
                                                            </td>
                                                            <td>
                                                                <a target={"_blank"} href={`${bucketUrl}${x.File}`}>
                                                                    <i className="bi bi-folder-check" />
                                                                </a>
                                                            </td>
                                                            <td>
                                                                <a className="btn btn-sm btn-secondary" target={"_blank"}
                                                                    href={
                                                                        x?.File.includes("simplefileupload") ? x?.File :
                                                                            `${bucketUrl}${x?.File}`
                                                                    }>
                                                                    Download
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                :
                                                <tr>
                                                    <td colSpan={5}>No Files Uploaded</td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(Papers);
