import './App.css';
import { BrowserRouter as Router, Route } from "react-router-dom";
import PubLicRoutes from './components/pageroutes/publicroutes';
import PageRoutes from './components/pageroutes/pageroutes';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";

function App(props) {
    return (
    <div className=''>
      <Router>
        {props.loginData.length < 1 ? <PubLicRoutes /> : <PageRoutes />}
      </Router>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ToastContainer />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loginData: state.LoginDetails,
  };
};

export default connect(mapStateToProps, null)(App);
