import React from 'react'
import Loader from "../common/loader";
import PageTitle from '../common/pagetitle/pagetitle';
import axios from 'axios';
import { useEffect } from 'react';
import {projectURL, sendEmail, serverLink, projectName} from '../url';
import { useState } from 'react';
import ReportTable from "../common/table/report_table";
import { toast } from "react-toastify";
import { connect } from 'react-redux';
import { showAlert } from '../common/sweetalert/sweetalert';
import JoditEditor from "jodit-react";

const NewApplications = (props) => {
    const editorBio = React.createRef();
    const [isloading, setIsLoading] = useState(true);
    const column = ["S/N", "APPLICATION ID", "FULL Name", "EMAIL ADDRESS", "Area Of Training", "Action"];
    const [data, setData] = useState([]);
    const [tableHeight, setTableHeight] = useState("600px");
    const [formData, setFormData] = useState({
        Cohort: "",
        RejectReason:"",
        InsertedBy: props.loginData[0].EmailAddress,
        EntryID:"",
        FullName:"",
        EmailAddress:"",
        AreaOfTraining:"",
        PhoneNumber:"",
        Gender:"",
        IsDisabled:"",
        HasLaptop:"",
        Organisation:"",
        HighestQualification:"",
        EmploymentStatus:"",
        CourseID:"",
        Status:"Active",
        CourseName:"",
        FirstName:"",
        MiddleName:"",
        Surname:"",
    })

    const getData = async () => {
        await axios.get(`${serverLink}foundation/dashboard/application/new`).then((res) => {
            const result = res.data;
            if (result.length > 0) {
                let rows = [];
                result.map((applicant, index) => {
                    rows.push([
                        index + 1,
                        applicant.EntryID,
                        applicant.FullName,
                        applicant.EmailAddress,
                        applicant.AreaOfTraining,
                        <>
                        <button data-bs-toggle="modal" data-bs-target="#basicModal"
                                className="btn btn-sm btn-success text-white"
                                onClick={() => {
                                    setFormData({
                                        ...formData,
                                        EntryID: applicant.EntryID,
                                        FullName: applicant.FullName,
                                        FirstName:applicant.FirstName,
                                        MiddleName:applicant.MiddleName,
                                        Surname:applicant.Surname,
                                        AreaOfTraining: applicant.AreaOfTraining,
                                        EmailAddress:applicant.EmailAddress,
                                        CourseName:applicant.CourseName,
                                        PhoneNumber:applicant.PhoneNumber,
                                        Gender:applicant.Gender,
                                        IsDisabled:applicant.IsDisabled,
                                        HasLaptop:applicant.HasLaptop,
                                        Organisation:applicant.Organisation,
                                        HighestQualification:applicant.HighestQualification,
                                        EmploymentStatus:applicant.EmploymentStatus,
                                        CourseID:applicant.CourseID,
                                        Status:"Active",
                                    })
                                }}
                            >
                                <i className="bi bi-check-circle" />
                            </button>,
                            <button data-bs-toggle="modal" data-bs-target="#rejectModal"
                            className="btn btn-sm btn-danger text-white"
                            onClick={() => {
                                setFormData({
                                    ...formData,
                                    EntryID: applicant.EntryID,
                                    FullName: applicant.FullName,
                                    AreaOfTraining: applicant.AreaOfTraining,
                                    EmailAddress:applicant.EmailAddress,
                                    CourseName:applicant.CourseName
                                })
                            }}
                        >
                            <i className="bi bi-x-circle-fill" />
                        </button>
                        </>
                    ]);
                });
                setTableHeight(result.length > 100 ? "1000px" : "600px");
                setData(rows);
            } else {
                toast.error("There are no new application available");
            }
            setIsLoading(false);
        })
            .catch((err) => {
                toast.error("NETWORK ERROR");
            });
    }

    const approve = async (e) => {
        e.preventDefault();
        if (formData.Cohort === "") {
            toast.error("Please select Cohort");
            return false;
        }
        setIsLoading(true);
        try {
            await axios.patch(`${serverLink}foundation/dashboard/approve/${formData.EntryID}`, formData).then(async (res) => {
                if (res.data.message === "success") {
                    toast.success('Application approved successfully')
                    getData();
                    setIsLoading(false);
                    setTimeout(() => {
                        window.location.href = 'new-applications';
                    }, 2000)
                    await axios.post(`${serverLink}foundation/login/enrolment`, formData).then((result)=> {
                        if (result.data.message === "success") {
                            const StudentID = result.data.StudentID;
                            sendEmail(
                                `${formData.EmailAddress}`,
                                `IT Services Department`,
                                "Student IT Accounts",
                                `${formData.FirstName} ${formData.MiddleName} ${formData.Surname}`,
                                `
                                     <p>Student ID: <b>${StudentID}</b></p>
                                    <p>Student Name: <b>${formData.FirstName} ${formData.MiddleName} ${formData.Surname}</b></p>
                                    <p>Email Address: <b>${formData.EmailAddress} </b></p>
                                    <p>Course: <b>${formData.CourseName} </b></p>
                                    
                                    <p><a target="_blank" href="https://ideas.bazeuniversity.edu.ng/admission-letter/${btoa(formData.EntryID)}">Print admission letter</a></p>
                                     <p>*If you have forgotten or do not have your user login details, kindly go contsct the IDEAS team. </p>
                                       <h3 style="padding-top: 20px"><strong>Helpful Links</strong></h3>
                                    <p>Website: ${projectURL}</p>      
                                      `,
                                `Regards,<br/>Admissions Officer<br/>${projectName}`
                            )
                        }
                    })
                    // const sendEmailData = {
                    //     from: 'ideas@bazeuniversity.edu.ng',
                    //     to: formData.EmailAddress,
                    //     name:formData.FullName,
                    //     course: formData.CourseName,
                    // }
                    // await axios.post(`${serverLink}foundation/dashboard/send_email/approve`, sendEmailData).then(r => {
                    // })
                } else {
                    showAlert("error", "Network error, please check your connection", "error")
                }
            })
        } catch (e) {
            console.log(e)
            setIsLoading(false)
            showAlert("error", "Network Error, try again", "error");
        }

    }

    const reject = async (e) => {
        e.preventDefault();
        if (formData.RejectReason === "") {
            toast.error("Please enter rejection reason");
            return false;
        }
        setIsLoading(true);
        try {
            await axios.patch(`${serverLink}foundation/dashboard/reject/${formData.EntryID}`, formData).then(async (res) => {
                if (res.data.message === "success") {
                    toast.success('Application rejected')
                    getData();
                    Reset();
                    setIsLoading(false);
                    setTimeout(() => {
                        window.location.href = 'new-applications';
                    }, 2000)
                    const sendEmailData = {
                        from: 'ideas@bazeuniversity.edu.ng',
                        to: formData.EmailAddress,
                        name:formData.FullName,
                        course: formData.CourseName,
                    }
                    await axios.post(`${serverLink}foundation/dashboard/send_email/reject`, sendEmailData).then(r => {
                    })
                } else {
                    showAlert("error", "Network error, please check your connection", "error")
                }
            })
        } catch (e) {
            console.log(e)
            setIsLoading(false)
            showAlert("error", "Network Error, try again", "error");
        }

    }

    const Reset = () => {
        setFormData({
            ...formData,
            FullName: "",
            Cohort: "",
            RejectReason:"",
            AreaOfTraining:""
        })
    }

    useEffect(() => {
        getData();
    }, [])

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        })
    }

    const onBioChange = (e) => {
        setFormData({
            ...formData,
            RejectReason: e
        })
    }


    return isloading === true ?
        (<Loader />) : (
            <>
                <PageTitle title={["Administartor", "New Applications"]} />

                <ReportTable title={`New Applications`} columns={column} data={data} height={tableHeight}
                />

                <div className="modal fade" id="basicModal" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content modal-lg">
                            <div className="modal-header">
                                <h5 className="modal-title">Approve Application</h5>
                                <button type="button" id="btn_close" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body">
                                <form onSubmit={approve}>
                                    <p>Are you sure you want to approve <b>{formData.FullName}'s </b> application for <b>{formData.AreaOfTraining}? </b></p>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="Cohort" className="col-md-12 col-lg-12 col-form-label"><b>Select Cohort</b></label>
                                                    <select className="form-control form-select" required onChange={onEdit} id="Cohort" value={formData.Cohort}>
                                                        <option value={""}>-select Cohort-</option>
                                                        <option value="CohortA">Cohort A</option>
                                                        <option value="CohortB">Cohort B</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="row mb-3">
                                                <div className="col-md-12 text-center">
                                                    <button className="btn btn-lg btn-success col-md-6" type="submit" disabled={isloading ? true : false}>
                                                        {
                                                            isloading === true &&
                                                            <>
                                                                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" />&emsp;
                                                            </>
                                                        }
                                                        {isloading === true ? "Loading..." :
                                                            "Approve"}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="rejectModal" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content modal-lg">
                            <div className="modal-header">
                                <h5 className="modal-title">Reject Application</h5>
                                <button type="button" id="btn_close" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body">
                                <form onSubmit={reject}>
                                    <p>Are you sure you want to reject <b>{formData.FullName}'s </b> application for <b>{formData.AreaOfTraining}? </b></p>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="RejectReason" className="col-md-12 col-lg-12 col-form-label"><b>Rejection Reason</b></label>
                                                    <JoditEditor
                                                        value={formData.RejectReason}
                                                        ref={editorBio}
                                                        tabIndex={1}
                                                        onChange={onBioChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="row mb-3">
                                                <div className="col-md-12 text-center">
                                                    <button className="btn btn-lg btn-danger col-md-6" type="submit" disabled={isloading ? true : false}>
                                                        {
                                                            isloading === true &&
                                                            <>
                                                                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" />&emsp;
                                                            </>
                                                        }
                                                        {isloading === true ? "Loading..." :
                                                            "Reject"}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(NewApplications);
