import React from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom'

const SideBar = (props) => {

    return (
        <>
            <aside id="sidebar" className="sidebar" >
                <ul className="sidebar-nav" id="sidebar-nav">
                    <li className="nav-item">
                        <Link className="nav-link " to="/dashboard">
                            <i className="bi bi-grid" />
                            <span>Dashboard</span>
                        </Link>
                    </li>
                    {
                        props.loginData[0].Role === "Admin" &&
                        <>
                            <li className="nav-item">
                                <a className="nav-link collapsed" data-bs-target="#components-nav3" data-bs-toggle="collapse" href="#">
                                    <i className="bi bi-check-circle" /><span>MBBS Result Notification</span><i className="bi bi-chevron-down ms-auto" />
                                </a>
                                <ul id="components-nav3" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                                    <li className="nav-item">
                                        <Link to="/admin/notify">
                                            <i className="bi bi-grid" /><span>Send Result</span>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        </>

                    }

                </ul>
            </aside>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};



export default connect(mapStateToProps, null)(SideBar);
