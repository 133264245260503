import React, {useEffect, useRef, useState} from 'react';
import Loader from '../common/loader';
import axios from 'axios';
import { serverLink } from '../url';
import ReportTable from '../common/table/report_table';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { StateData } from '../state_data';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import ApexChart from "react-apexcharts";
import ApexChart1 from "react-apexcharts";
import ApexChart2 from "react-apexcharts";
import ApexChart3 from "react-apexcharts";
import ApexChart4 from "react-apexcharts";

const Reports = (props) => {
    const [apexChartOption, setApexChartOption] = useState({});
    const [apexSeries, setApexSeries] = useState([]);

    const [apexChartOption1, setApexChartOption1] = useState({});
    const [apexSeries1, setApexSeries1] = useState([])

    const [apexChartOption2, setApexChartOption2] = useState({});
    const [apexSeries2, setApexSeries2] = useState([])

    const [apexChartOption3, setApexChartOption3] = useState({});
    const [apexSeries3, setApexSeries3] = useState([])

    const [apexChartOption4, setApexChartOption4] = useState({});
    const [apexSeries4, setApexSeries4] = useState([])

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [canPrint, setCanPrint] = useState(false);
    const [printableData, setPrintableData] = useState([]);
    const [position, setPosition] = useState(0);
    const componentRef = useRef();
    const [stateList, setStateList] = useState([]);
    const [lgaList, setLGAList] = useState([]);
    const location = useLocation();

    const [createItem, setCreateItem] = useState({
        ReportType: "",
        State: "",
        LGA: "",
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${serverLink}foundation/dashboard/application/all`);
                setData(response.data);
                console.log("data from report", response.data)
            } catch (error) {
                console.error('Error fetching data:', error);
                toast.error('Error fetching data');
            } finally {
                setIsLoading(false);
            }
        };

        fetchData().then(r => {});

    }, []);

    useEffect(() => {
        Graph()
        Graph1()
        Graph2()
        Graph3()
        Graph4()
    }, [createItem.ReportType]);

    useEffect(() => {
        setStateList([...new Set(StateData.map(item => item.StateName))])
    }, [location.pathname]);

    // const stateApplicantsCount = data.reduce((accumulator, applicant) => {
    //     const state = applicant.State;
    //     accumulator[state] = (accumulator[state] || 0) + 1;
    //     return accumulator;
    // }, {});

    const stateApplicantsCount = data.reduce((accumulator, applicant) => {
        const state = applicant.State;
        const mode = applicant.TrainingMode;

        // Initialize the accumulator for the state if not present
        accumulator[state] = accumulator[state] || { total: 0, modes: {}, mode: '' };

        // Increment the total count for the state
        accumulator[state].total++;

        // Increment the count for the specific mode within the state
        accumulator[state].modes[mode] = (accumulator[state].modes[mode] || 0) + 1;

        // Set the mode attribute for the state
        accumulator[state].mode = mode;

        return accumulator;
    }, {});

    const distinctStates = [...new Set(data.map(applicant => applicant.State))];
    let analyticData = [];
    let analyticCount = [];

    distinctStates.map((x=>{
      let Statecount =   data.filter((items)=>items.State === x).length;
      analyticData.push(x)
        analyticCount.push(Statecount)
    }))

    let isDisabledData= [];
    let isDisabledCount= [];
   const distictDisability = [
       {name: "With Disability", count: 1},{name: "Without Disability", count: 0},
   ];
    distictDisability.map((x=>{
        let yesCount =   data.filter((items)=>items.IsDisabled === x.count).length;
        isDisabledData.push(x.name)
        isDisabledCount.push(yesCount)
    }))


    let isHaslaptopData= [];
    let isHaslaptopCount= [];
    const distictLaptop = [
        {name: "With Laptop", count: 1},{name: "Without Laptop", count: 0},
    ];
    distictLaptop.map((x=>{
        let yesCount =   data.filter((items)=>items.HasLaptop === x.count).length;
        isHaslaptopData.push(x.name)
        isHaslaptopCount.push(yesCount)
    }))


    let genderData= [];
    let genderCount= [];
    const distinctGender = [...new Set(data.map(applicant => applicant.Gender))];
    distinctGender.map((x=>{
        let yesCount =   data.filter((items)=>items.Gender === x).length;
        genderData.push(x)
        genderCount.push(yesCount)
    }))

    let courseData= [];
    let courseCount= [];
    const distinctCourse = [...new Set(data.map(applicant => applicant.CourseName))];
    const removedPrefixCourses = distinctCourse.map(course => course.replace('Professional Diploma in ', ''));

    removedPrefixCourses.map((x=>{
        let yesCount =   data.filter((items)=>items.CourseName.replace('Professional Diploma in ', '') === x).length;
        courseData.push(x)
        courseCount.push(yesCount)
    }))


   const  Graph = ()=>{
       setApexChartOption({
           ...apexChartOption,
           chart: {
               id: "basic-bar"
           },
           xaxis: {
               categories: analyticData
           }
       })
       setApexSeries([{
           name: "Count",
           data: analyticCount
       }])
   }

    const  Graph1 = ()=>{
        setApexChartOption1({
            ...apexChartOption1,
            chart: {
                id: "basic-bar"
            },
            xaxis: {
                categories: isDisabledData
            }
        })
        setApexSeries1([{
            name: "Count",
            data: isDisabledCount
        }])
    }

    const  Graph2 = ()=>{
        setApexChartOption2({
            ...apexChartOption2,
            chart: {
                id: "basic-bar"
            },
            xaxis: {
                categories: isHaslaptopData
            }
        })
        setApexSeries2([{
            name: "Count",
            data: isHaslaptopCount
        }])
    }

    const  Graph3 = ()=>{
        setApexChartOption3({
            ...apexChartOption3,
            chart: {
                id: "basic-bar"
            },
            xaxis: {
                categories: genderData
            }
        })
        setApexSeries3([{
            name: "Count",
            data: genderCount
        }])
    }

    const  Graph4 = ()=>{
        setApexChartOption4({
            ...apexChartOption4,
            chart: {
                id: "basic-bar"
            },
            xaxis: {
                categories: courseData
            }
        })
        setApexSeries4([{
            name: "Count",
            data: courseCount
        }])
    }



    const distinctLGAs = [...new Set(data.map(applicant => applicant.LGA))];

    // console.log('Distinct States:', distinctStates);
    // console.log('Distinct LGAs:', distinctLGAs);

    const sortedStateApplicants = Object.entries(stateApplicantsCount).sort(
        (a, b) => b[1] - a[1]
    );

    const onEdit = (e) => {
        setCreateItem({
            ...createItem,
            [e.target.id]: e.target.value,
        });

        setPrintableData([]);
        setCanPrint(false);
    };

    return isLoading ? (
        <Loader />
    ) : data.length > 0 ? (
        // <>
        //     <div id="wrapper">
        //         <div id="page-wrapper">
        //             <div className="header">
        //                 <h1 className="page-header">Report</h1>
        //             </div>
        //             <div id="page-inner">
        //                 <div className="row">
        //                     <div className="col-md-12">
        //                         <div className="panel panel-default">
        //                             <div className="panel-heading"></div>
        //
        //                             <div className="panel-body">
        //                                 <div className="col-md-12">
        //                                     <div className="modal-body">
        //                                         <div className="col-lg-6">
        //                                             <div className="form-group">
        //                                                 <select
        //                                                     id="ReportType"
        //                                                     className="form-control"
        //                                                     value={createItem.ReportType}
        //                                                     onChange={onEdit}
        //                                                 >
        //                                                     <option value="">Select Report</option>
        //                                                     <option value="State">State</option>
        //                                                     <option value="LGA">LGA</option>
        //                                                 </select>
        //                                             </div>
        //                                         </div>
        //
        //                                         {createItem.ReportType === "State" && (
        //                                             <>
        //                                                 <div className="col-xxl-6">
        //                                                     <div className="country-select">
        //                                                         <select id="state" className="form-control" value={createItem.state}
        //                                                                 onChange={(e) => {
        //                                                                     setCreateItem({ ...createItem, [e.target.id]: e.target.value, nationality: e.target.value === 'Non-Nigerian' ? 'Non-Nigerian' : 'Nigeria' });
        //                                                                     setLGAList([...new Set(StateData.filter(p => p.StateName === e.target.value).map(item => item.LGAName))]);
        //                                                                 }}>
        //                                                             <option value="">Select State</option>
        //                                                             {
        //                                                                 stateList.length > 0 &&
        //                                                                 stateList.map((state, i) => {
        //                                                                     return <option key={i} value={state}>{state}</option>
        //                                                                 })
        //                                                             }
        //                                                         </select>
        //                                                     </div>
        //                                                 </div>
        //                                             </>
        //                                         )}
        //
        //
        //                                         {createItem.ReportType === "LGA" && (
        //                                             <>
        //                                                 <div className="col-xxl-6">
        //                                                     <div className="country-select">
        //                                                         <select id="state" className="form-control" value={createItem.State}
        //                                                                 onChange={(e) => {
        //                                                                     setCreateItem({ ...createItem, [e.target.id]: e.target.value, nationality: e.target.value === 'Non-Nigerian' ? 'Non-Nigerian' : 'Nigeria' });
        //                                                                     setLGAList([...new Set(StateData.filter(p => p.StateName === e.target.value).map(item => item.LGAName))]);
        //                                                                 }}>
        //                                                             <option value="">Select State</option>
        //                                                             {
        //                                                                 stateList.length > 0 &&
        //                                                                 stateList.map((state, i) => {
        //                                                                     return <option key={i} value={state}>{state}</option>
        //                                                                 })
        //                                                             }
        //                                                         </select>
        //                                                     </div>
        //                                                 </div>
        //
        //                                                 <div className="col-xxl-6">
        //                                                     <div className="country-select">
        //                                                         <select id="lga" className="form-control" value={createItem.LGA}
        //                                                                 onChange={(e) => {
        //                                                                     setCreateItem({ ...createItem, [e.target.id]: e.target.value });
        //                                                                 }}>
        //                                                             <option value="">Select LGA</option>
        //                                                             {
        //                                                                 lgaList.length > 0 &&
        //                                                                 lgaList.map((lga, i) => {
        //                                                                     return <option key={i} value={lga}>{lga}</option>
        //                                                                 })
        //                                                             }
        //                                                         </select>
        //                                                     </div>
        //                                                 </div>
        //                                             </>
        //                                         )}
        //
        //                                         {/*<div className="col-lg-12">*/}
        //                                         {/*    {createItem.ReportType !== "" &&*/}
        //                                         {/*        printableData &&*/}
        //                                         {/*        Object.keys(printableData).length ===*/}
        //                                         {/*        0 && (*/}
        //                                         {/*            <button*/}
        //                                         {/*                type="button"*/}
        //                                         {/*                className="btn btn-primary col-lg-12"*/}
        //                                         {/*                onClick={onSubmit}*/}
        //                                         {/*            >*/}
        //                                         {/*                Generate Report*/}
        //                                         {/*            </button>*/}
        //                                         {/*        )}*/}
        //                                         {/*</div>*/}
        //
        //
        //                                         {/*{createItem.ReportType === "State" && (*/}
        //                                         {/*    <>*/}
        //                                         {/*        {createItem.Mode === "Location" && (*/}
        //                                         {/*            <>*/}
        //                                         {/*                <div className="col-lg-6">*/}
        //                                         {/*                    <div className="form-group">*/}
        //                                         {/*                        <select*/}
        //                                         {/*                            className="form-control"*/}
        //                                         {/*                            id="LocationID"*/}
        //                                         {/*                            name="LocationID"*/}
        //                                         {/*                            onChange={onEdit}*/}
        //                                         {/*                            value={createItem.LocationID}*/}
        //                                         {/*                            disabled={*/}
        //                                         {/*                                createItem.Mode === "" && true*/}
        //                                         {/*                            }*/}
        //                                         {/*                        >*/}
        //                                         {/*                            <option value="">*/}
        //                                         {/*                                Select Location*/}
        //                                         {/*                            </option>*/}
        //                                         {/*                            {states.length > 0 &&*/}
        //                                         {/*                                states.map((item, index) => {*/}
        //                                         {/*                                    return (*/}
        //                                         {/*                                        <option*/}
        //                                         {/*                                            key={item.StateId}*/}
        //                                         {/*                                            value={item.StateId}*/}
        //                                         {/*                                        >*/}
        //                                         {/*                                            {item.Title}*/}
        //                                         {/*                                        </option>*/}
        //                                         {/*                                    );*/}
        //                                         {/*                                })}*/}
        //                                         {/*                        </select>*/}
        //                                         {/*                    </div>*/}
        //                                         {/*                </div>*/}
        //
        //                                         {/*                <div*/}
        //                                         {/*                    className={*/}
        //                                         {/*                        createItem.Mode === "Location"*/}
        //                                         {/*                            ? "col-lg-6"*/}
        //                                         {/*                            : "col-lg-12"*/}
        //                                         {/*                    }*/}
        //                                         {/*                >*/}
        //                                         {/*                    /!*<div className="col-lg-12">*!/*/}
        //                                         {/*                    <div className="form-group">*/}
        //                                         {/*                        <select*/}
        //                                         {/*                            className="form-control"*/}
        //                                         {/*                            id="Date"*/}
        //                                         {/*                            name="Date"*/}
        //                                         {/*                            onChange={onEdit}*/}
        //                                         {/*                            value={createItem.Date}*/}
        //                                         {/*                            disabled={*/}
        //                                         {/*                                createItem.LocationID === "" &&*/}
        //                                         {/*                                createItem.Mode !== "All" &&*/}
        //                                         {/*                                true*/}
        //                                         {/*                            }*/}
        //                                         {/*                        >*/}
        //                                         {/*                            <option value="">Select Year</option>*/}
        //                                         {/*                            {uniqueProjectsYear.length > 0 &&*/}
        //                                         {/*                                uniqueProjectsYear.map(*/}
        //                                         {/*                                    (item, index) => {*/}
        //                                         {/*                                        return (*/}
        //                                         {/*                                            <option*/}
        //                                         {/*                                                key={index}*/}
        //                                         {/*                                                value={item}*/}
        //                                         {/*                                            >*/}
        //                                         {/*                                                {item}*/}
        //                                         {/*                                            </option>*/}
        //                                         {/*                                        );*/}
        //                                         {/*                                    }*/}
        //                                         {/*                                )}*/}
        //                                         {/*                        </select>*/}
        //                                         {/*                    </div>*/}
        //                                         {/*                </div>*/}
        //
        //                                         {/*                <div className="col-lg-12">*/}
        //                                         {/*                    {createItem.Date !== "" &&*/}
        //                                         {/*                        createItem.Mode !== "" &&*/}
        //                                         {/*                        createItem.ReportType !== "" &&*/}
        //                                         {/*                        printableData &&*/}
        //                                         {/*                        Object.keys(printableData).length ===*/}
        //                                         {/*                        0 && (*/}
        //                                         {/*                            <button*/}
        //                                         {/*                                type="button"*/}
        //                                         {/*                                className="btn btn-primary col-lg-12"*/}
        //                                         {/*                                onClick={onSubmit}*/}
        //                                         {/*                            >*/}
        //                                         {/*                                Generate Report*/}
        //                                         {/*                            </button>*/}
        //                                         {/*                        )}*/}
        //                                         {/*                </div>*/}
        //                                         {/*            </>*/}
        //                                         {/*        )}*/}
        //
        //                                         {/*        {createItem.Mode === "Year" && (*/}
        //                                         {/*            <>*/}
        //                                         {/*                <div className="col-lg-12">*/}
        //                                         {/*                    <div className="form-group">*/}
        //                                         {/*                        <select*/}
        //                                         {/*                            className="form-control"*/}
        //                                         {/*                            id="Date"*/}
        //                                         {/*                            name="Date"*/}
        //                                         {/*                            onChange={onEdit}*/}
        //                                         {/*                            value={createItem.Date}*/}
        //                                         {/*                        >*/}
        //                                         {/*                            <option value="">Select Year</option>*/}
        //                                         {/*                            {uniqueProjectsYear.length > 0 &&*/}
        //                                         {/*                                uniqueProjectsYear.map(*/}
        //                                         {/*                                    (item, index) => {*/}
        //                                         {/*                                        return (*/}
        //                                         {/*                                            <option*/}
        //                                         {/*                                                key={index}*/}
        //                                         {/*                                                value={item}*/}
        //                                         {/*                                            >*/}
        //                                         {/*                                                {item}*/}
        //                                         {/*                                            </option>*/}
        //                                         {/*                                        );*/}
        //                                         {/*                                    }*/}
        //                                         {/*                                )}*/}
        //                                         {/*                        </select>*/}
        //                                         {/*                    </div>*/}
        //                                         {/*                </div>*/}
        //
        //                                         {/*                <div className="col-lg-12">*/}
        //                                         {/*                    {createItem.Date !== "" &&*/}
        //                                         {/*                        createItem.Mode !== "" &&*/}
        //                                         {/*                        createItem.ReportType !== "" &&*/}
        //                                         {/*                        printableData &&*/}
        //                                         {/*                        Object.keys(printableData).length ===*/}
        //                                         {/*                        0 && (*/}
        //                                         {/*                            <button*/}
        //                                         {/*                                type="button"*/}
        //                                         {/*                                className="btn btn-primary col-lg-12"*/}
        //                                         {/*                                onClick={onSubmit}*/}
        //                                         {/*                            >*/}
        //                                         {/*                                Generate Report*/}
        //                                         {/*                            </button>*/}
        //                                         {/*                        )}*/}
        //                                         {/*                </div>*/}
        //                                         {/*            </>*/}
        //                                         {/*        )}*/}
        //
        //                                         {/*        <div className="col-lg-12">*/}
        //                                         {/*            {createItem.Mode === "All" &&*/}
        //                                         {/*                Object.keys(printableData).length === 0 && (*/}
        //                                         {/*                    <button*/}
        //                                         {/*                        type="button"*/}
        //                                         {/*                        className="btn btn-primary col-lg-12"*/}
        //                                         {/*                        onClick={onSubmit}*/}
        //                                         {/*                    >*/}
        //                                         {/*                        Generate Report*/}
        //                                         {/*                    </button>*/}
        //                                         {/*                )}*/}
        //                                         {/*        </div>*/}
        //                                         {/*    </>*/}
        //                                         {/*)}*/}
        //
        //                                         {/*{createItem.ReportType === "LGA" && (*/}
        //                                         {/*    <>*/}
        //                                         {/*        {createItem.Mode === "Location" && (*/}
        //                                         {/*            <>*/}
        //                                         {/*                <div className="col-lg-6">*/}
        //                                         {/*                    <div className="form-group">*/}
        //                                         {/*                        <select*/}
        //                                         {/*                            className="form-control"*/}
        //                                         {/*                            id="LocationID"*/}
        //                                         {/*                            name="LocationID"*/}
        //                                         {/*                            onChange={onEdit}*/}
        //                                         {/*                            value={createItem.LocationID}*/}
        //                                         {/*                            disabled={*/}
        //                                         {/*                                createItem.Mode === "" && true*/}
        //                                         {/*                            }*/}
        //                                         {/*                        >*/}
        //                                         {/*                            <option value="">*/}
        //                                         {/*                                Select Location*/}
        //                                         {/*                            </option>*/}
        //                                         {/*                            {states.length > 0 &&*/}
        //                                         {/*                                states.map((item, index) => {*/}
        //                                         {/*                                    return (*/}
        //                                         {/*                                        <option*/}
        //                                         {/*                                            key={item.StateId}*/}
        //                                         {/*                                            value={item.StateId}*/}
        //                                         {/*                                        >*/}
        //                                         {/*                                            {item.Title}*/}
        //                                         {/*                                        </option>*/}
        //                                         {/*                                    );*/}
        //                                         {/*                                })}*/}
        //                                         {/*                        </select>*/}
        //                                         {/*                    </div>*/}
        //                                         {/*                </div>*/}
        //
        //                                         {/*                <div*/}
        //                                         {/*                    className={*/}
        //                                         {/*                        createItem.Mode === "Location"*/}
        //                                         {/*                            ? "col-lg-6"*/}
        //                                         {/*                            : "col-lg-12"*/}
        //                                         {/*                    }*/}
        //                                         {/*                >*/}
        //                                         {/*                    <div className="form-group">*/}
        //                                         {/*                        <input*/}
        //                                         {/*                            type="date"*/}
        //                                         {/*                            id="Date"*/}
        //                                         {/*                            className="form-control"*/}
        //                                         {/*                            placeholder="Date"*/}
        //                                         {/*                            value={formatDate(createItem.Date)}*/}
        //                                         {/*                            onChange={onEdit}*/}
        //                                         {/*                            disabled={*/}
        //                                         {/*                                createItem.LocationID === "" &&*/}
        //                                         {/*                                createItem.Mode !== "All" &&*/}
        //                                         {/*                                true*/}
        //                                         {/*                            }*/}
        //                                         {/*                        />*/}
        //                                         {/*                    </div>*/}
        //                                         {/*                </div>*/}
        //
        //                                         {/*                <div className="col-lg-12">*/}
        //                                         {/*                    {createItem.Date !== "" &&*/}
        //                                         {/*                        createItem.Mode !== "" &&*/}
        //                                         {/*                        createItem.ReportType !== "" &&*/}
        //                                         {/*                        printableData &&*/}
        //                                         {/*                        Object.keys(printableData).length ===*/}
        //                                         {/*                        0 && (*/}
        //                                         {/*                            <button*/}
        //                                         {/*                                type="button"*/}
        //                                         {/*                                className="btn btn-primary col-lg-12"*/}
        //                                         {/*                                onClick={onSubmit}*/}
        //                                         {/*                            >*/}
        //                                         {/*                                Generate Report*/}
        //                                         {/*                            </button>*/}
        //                                         {/*                        )}*/}
        //                                         {/*                </div>*/}
        //                                         {/*            </>*/}
        //                                         {/*        )}*/}
        //
        //                                         {/*        {createItem.Mode === "Year" && (*/}
        //                                         {/*            <>*/}
        //                                         {/*                <div className={"col-lg-12"}>*/}
        //                                         {/*                    <div className="form-group">*/}
        //                                         {/*                        <input*/}
        //                                         {/*                            type="date"*/}
        //                                         {/*                            id="Date"*/}
        //                                         {/*                            className="form-control"*/}
        //                                         {/*                            placeholder="Date"*/}
        //                                         {/*                            value={formatDate(createItem.Date)}*/}
        //                                         {/*                            onChange={onEdit}*/}
        //                                         {/*                        />*/}
        //                                         {/*                    </div>*/}
        //                                         {/*                </div>*/}
        //
        //                                         {/*                <div className="col-lg-12">*/}
        //                                         {/*                    {createItem.Date !== "" &&*/}
        //                                         {/*                        createItem.Mode !== "" &&*/}
        //                                         {/*                        createItem.ReportType !== "" &&*/}
        //                                         {/*                        printableData &&*/}
        //                                         {/*                        Object.keys(printableData).length ===*/}
        //                                         {/*                        0 && (*/}
        //                                         {/*                            <button*/}
        //                                         {/*                                type="button"*/}
        //                                         {/*                                className="btn btn-primary col-lg-12"*/}
        //                                         {/*                                onClick={onSubmit}*/}
        //                                         {/*                            >*/}
        //                                         {/*                                Generate Report*/}
        //                                         {/*                            </button>*/}
        //                                         {/*                        )}*/}
        //                                         {/*                </div>*/}
        //                                         {/*            </>*/}
        //                                         {/*        )}*/}
        //
        //                                         {/*        <div className="col-lg-12">*/}
        //                                         {/*            {createItem.Mode === "All" &&*/}
        //                                         {/*                Object.keys(printableData).length === 0 && (*/}
        //                                         {/*                    <button*/}
        //                                         {/*                        type="button"*/}
        //                                         {/*                        className="btn btn-primary col-lg-12"*/}
        //                                         {/*                        onClick={onSubmit}*/}
        //                                         {/*                    >*/}
        //                                         {/*                        Generate Report*/}
        //                                         {/*                    </button>*/}
        //                                         {/*                )}*/}
        //                                         {/*        </div>*/}
        //                                         {/*    </>*/}
        //                                         {/*)}*/}
        //
        //                                         {/*<div className="col-lg-12">*/}
        //                                         {/*    {printableData &&*/}
        //                                         {/*        Object.keys(printableData).length > 0 && (*/}
        //                                         {/*            <button*/}
        //                                         {/*                type="button"*/}
        //                                         {/*                className="btn btn-primary col-lg-12"*/}
        //                                         {/*                onClick={generateReceipt}*/}
        //                                         {/*            >*/}
        //                                         {/*                Print Report*/}
        //                                         {/*            </button>*/}
        //                                         {/*        )}*/}
        //                                         {/*</div>*/}
        //
        //                                         {/*/!*PRINT PROJECT REPORT*!/*/}
        //                                         {/*{createItem.ReportType === "Project" && (*/}
        //                                         {/*    <>*/}
        //                                         {/*        <div className="col-lg-12">*/}
        //                                         {/*            <br />*/}
        //                                         {/*            {canPrint &&*/}
        //                                         {/*            Object.keys(printableData).length > 0 ? (*/}
        //                                         {/*                <div*/}
        //                                         {/*                    ref={componentRef}*/}
        //                                         {/*                    style={{*/}
        //                                         {/*                        // display: "flex",*/}
        //                                         {/*                        alignItems: "center",*/}
        //                                         {/*                        marginLeft: "20px",*/}
        //                                         {/*                        marginRight: "20px",*/}
        //                                         {/*                        justifyContent: "center",*/}
        //                                         {/*                        width: "8.27in",*/}
        //                                         {/*                        height: "11.69in",*/}
        //                                         {/*                    }}*/}
        //                                         {/*                >*/}
        //                                         {/*                    <div>*/}
        //                                         {/*                        <div>*/}
        //                                         {/*                            <div>*/}
        //                                         {/*                                /!*Advanced Tables *!/*/}
        //                                         {/*                                <div>*/}
        //                                         {/*                                    <div*/}
        //                                         {/*                                        className="panel"*/}
        //                                         {/*                                        style={{*/}
        //                                         {/*                                            backgroundColor: "#dedede",*/}
        //                                         {/*                                        }}*/}
        //                                         {/*                                    >*/}
        //                                         {/*                                        <h4*/}
        //                                         {/*                                            style={{*/}
        //                                         {/*                                                display: "flex",*/}
        //                                         {/*                                                alignItems: "center",*/}
        //                                         {/*                                                justifyContent: "center",*/}
        //                                         {/*                                                marginBottom: "0",*/}
        //                                         {/*                                            }}*/}
        //                                         {/*                                        >*/}
        //                                         {/*                                            <img*/}
        //                                         {/*                                                src={logo}*/}
        //                                         {/*                                                alt="Logo"*/}
        //                                         {/*                                                style={{*/}
        //                                         {/*                                                    height: "140px",*/}
        //                                         {/*                                                    marginRight: "10px",*/}
        //                                         {/*                                                    marginBottom: "0",*/}
        //                                         {/*                                                }}*/}
        //                                         {/*                                            />*/}
        //                                         {/*                                        </h4>*/}
        //                                         {/*                                        <h3*/}
        //                                         {/*                                            style={{*/}
        //                                         {/*                                                display: "flex",*/}
        //                                         {/*                                                alignItems: "center",*/}
        //                                         {/*                                                justifyContent: "center",*/}
        //                                         {/*                                                marginTop: "0",*/}
        //                                         {/*                                            }}*/}
        //                                         {/*                                        >*/}
        //                                         {/*                                            {createItem.LocationID !==*/}
        //                                         {/*                                                "" &&*/}
        //                                         {/*                                                states.length > 0 &&*/}
        //                                         {/*                                                states*/}
        //                                         {/*                                                    .filter(*/}
        //                                         {/*                                                        (i) =>*/}
        //                                         {/*                                                            i.StateId ===*/}
        //                                         {/*                                                            Number(*/}
        //                                         {/*                                                                createItem.LocationID*/}
        //                                         {/*                                                            )*/}
        //                                         {/*                                                    )*/}
        //                                         {/*                                                    .map((r) => r.Title)}{" "}*/}
        //                                         {/*                                            NALDA {createItem.ReportType}{" "}*/}
        //                                         {/*                                            Report{" "}*/}
        //                                         {/*                                            {createItem.Mode !== "All" &&*/}
        //                                         {/*                                                createItem.Date.split(*/}
        //                                         {/*                                                    "-"*/}
        //                                         {/*                                                )[0]}*/}
        //                                         {/*                                        </h3>*/}
        //                                         {/*                                        /!*<h6*!/*/}
        //                                         {/*                                        /!*  style={{*!/*/}
        //                                         {/*                                        /!*    display: "flex",*!/*/}
        //                                         {/*                                        /!*    alignItems: "center",*!/*/}
        //                                         {/*                                        /!*    justifyContent: "center",*!/*/}
        //                                         {/*                                        /!*  }}*!/*/}
        //                                         {/*                                        /!*>*!/*/}
        //                                         {/*                                        /!*  Generated By:{" "}*!/*/}
        //                                         {/*                                        /!*  {currentUser.FullName}*!/*/}
        //                                         {/*                                        /!*</h6>*!/*/}
        //                                         {/*                                        <h6*/}
        //                                         {/*                                            style={{*/}
        //                                         {/*                                                display: "flex",*/}
        //                                         {/*                                                alignItems: "center",*/}
        //                                         {/*                                                justifyContent: "center",*/}
        //                                         {/*                                            }}*/}
        //                                         {/*                                        >*/}
        //                                         {/*                                            Generated Date:{" "}*/}
        //                                         {/*                                            {`${currentMonth}/${currentDay}/${currentYear}`}*/}
        //                                         {/*                                        </h6>*/}
        //                                         {/*                                    </div>*/}
        //                                         {/*                                    <div className="panel-body">*/}
        //                                         {/*                                        <div className="table-responsive">*/}
        //                                         {/*                                            <table*/}
        //                                         {/*                                                className="table table-striped table-bordered table-hover"*/}
        //                                         {/*                                                id="dataTables-example"*/}
        //                                         {/*                                            >*/}
        //                                         {/*                                                <thead>*/}
        //                                         {/*                                                <tr>*/}
        //                                         {/*                                                    <th>S/No</th>*/}
        //                                         {/*                                                    <th>Title</th>*/}
        //                                         {/*                                                    <th>LGA</th>*/}
        //                                         {/*                                                    <th>Project Status</th>*/}
        //                                         {/*                                                </tr>*/}
        //                                         {/*                                                </thead>*/}
        //                                         {/*                                                <tbody>*/}
        //                                         {/*                                                {printableData?.length >*/}
        //                                         {/*                                                    0 &&*/}
        //                                         {/*                                                    printableData.map(*/}
        //                                         {/*                                                        (item, index) => {*/}
        //                                         {/*                                                            return (*/}
        //                                         {/*                                                                <tr*/}
        //                                         {/*                                                                    className="gradeC"*/}
        //                                         {/*                                                                    key={index}*/}
        //                                         {/*                                                                >*/}
        //                                         {/*                                                                    <td>*/}
        //                                         {/*                                                                        {index + 1}*/}
        //                                         {/*                                                                    </td>*/}
        //                                         {/*                                                                    <td>*/}
        //                                         {/*                                                                        {item.Title}*/}
        //                                         {/*                                                                    </td>*/}
        //                                         {/*                                                                    <td>*/}
        //                                         {/*                                                                        {lga.length >*/}
        //                                         {/*                                                                            0 &&*/}
        //                                         {/*                                                                            lga*/}
        //                                         {/*                                                                                .filter(*/}
        //                                         {/*                                                                                    (i) =>*/}
        //                                         {/*                                                                                        i.LGAId ===*/}
        //                                         {/*                                                                                        Number(*/}
        //                                         {/*                                                                                            item.LGAId*/}
        //                                         {/*                                                                                        )*/}
        //                                         {/*                                                                                )*/}
        //                                         {/*                                                                                .map(*/}
        //                                         {/*                                                                                    (r) =>*/}
        //                                         {/*                                                                                        r.Title*/}
        //                                         {/*                                                                                )}*/}
        //                                         {/*                                                                    </td>*/}
        //                                         {/*                                                                    <td>*/}
        //                                         {/*                                                                        {item.Status}*/}
        //                                         {/*                                                                    </td>*/}
        //                                         {/*                                                                </tr>*/}
        //                                         {/*                                                            );*/}
        //                                         {/*                                                        }*/}
        //                                         {/*                                                    )}*/}
        //                                         {/*                                                <>*/}
        //                                         {/*                                                    <tr className="gradeC">*/}
        //                                         {/*                                                        <td></td>*/}
        //                                         {/*                                                        <td>*/}
        //                                         {/*                                                            <strong>*/}
        //                                         {/*                                                                Completed Project*/}
        //                                         {/*                                                            </strong>*/}
        //                                         {/*                                                        </td>*/}
        //                                         {/*                                                        <td>*/}
        //                                         {/*                                                            <strong>*/}
        //                                         {/*                                                                {completed}*/}
        //                                         {/*                                                            </strong>*/}
        //                                         {/*                                                        </td>*/}
        //                                         {/*                                                    </tr>*/}
        //                                         {/*                                                    <tr className="gradeC">*/}
        //                                         {/*                                                        <td></td>*/}
        //                                         {/*                                                        <td>*/}
        //                                         {/*                                                            <strong>*/}
        //                                         {/*                                                                Ongoing Project*/}
        //                                         {/*                                                            </strong>*/}
        //                                         {/*                                                        </td>*/}
        //                                         {/*                                                        <td>*/}
        //                                         {/*                                                            <strong>*/}
        //                                         {/*                                                                {ongoing}*/}
        //                                         {/*                                                            </strong>*/}
        //                                         {/*                                                        </td>*/}
        //                                         {/*                                                    </tr>*/}
        //
        //                                         {/*                                                    <tr className="gradeC">*/}
        //                                         {/*                                                        <td></td>*/}
        //                                         {/*                                                        <td>*/}
        //                                         {/*                                                            <strong>*/}
        //                                         {/*                                                                Total Projects*/}
        //                                         {/*                                                            </strong>*/}
        //                                         {/*                                                        </td>*/}
        //                                         {/*                                                        <td>*/}
        //                                         {/*                                                            <strong>*/}
        //                                         {/*                                                                {total}*/}
        //                                         {/*                                                            </strong>*/}
        //                                         {/*                                                        </td>*/}
        //                                         {/*                                                    </tr>*/}
        //                                         {/*                                                </>*/}
        //                                         {/*                                                </tbody>*/}
        //                                         {/*                                            </table>*/}
        //                                         {/*                                        </div>*/}
        //                                         {/*                                    </div>*/}
        //                                         {/*                                </div>*/}
        //                                         {/*                                /!*End Advanced Tables*!/*/}
        //                                         {/*                            </div>*/}
        //                                         {/*                        </div>*/}
        //                                         {/*                    </div>*/}
        //                                         {/*                </div>*/}
        //                                         {/*            ) : (*/}
        //                                         {/*                <>*/}
        //                                         {/*                    {canPrint && (*/}
        //                                         {/*                        <div className="panel-body">*/}
        //                                         {/*                            <div className="alert alert-info">*/}
        //                                         {/*                                <strong>Sorry!</strong> There is no*/}
        //                                         {/*                                data for the selected report.*/}
        //                                         {/*                            </div>*/}
        //                                         {/*                        </div>*/}
        //                                         {/*                    )}*/}
        //                                         {/*                </>*/}
        //                                         {/*            )}*/}
        //                                         {/*        </div>*/}
        //                                         {/*    </>*/}
        //                                         {/*)}*/}
        //
        //                                         {/*/!*PRINT ASSET REPORT*!/*/}
        //                                         {/*{createItem.ReportType === "Asset" && (*/}
        //                                         {/*    <>*/}
        //                                         {/*        <div className="col-lg-12">*/}
        //                                         {/*            <br />*/}
        //                                         {/*            {canPrint &&*/}
        //                                         {/*            Object.keys(printableData).length > 0 ? (*/}
        //                                         {/*                <div*/}
        //                                         {/*                    ref={componentRef}*/}
        //                                         {/*                    style={{*/}
        //                                         {/*                        // display: "flex",*/}
        //                                         {/*                        alignItems: "center",*/}
        //                                         {/*                        marginLeft: "20px",*/}
        //                                         {/*                        marginRight: "20px",*/}
        //                                         {/*                        justifyContent: "center",*/}
        //                                         {/*                        width: "8.27in",*/}
        //                                         {/*                        height: "11.69in",*/}
        //                                         {/*                    }}*/}
        //                                         {/*                >*/}
        //                                         {/*                    <div>*/}
        //                                         {/*                        <div>*/}
        //                                         {/*                            <div>*/}
        //                                         {/*                                /!*Advanced Tables *!/*/}
        //                                         {/*                                <div>*/}
        //                                         {/*                                    <div*/}
        //                                         {/*                                        className="panel"*/}
        //                                         {/*                                        style={{*/}
        //                                         {/*                                            backgroundColor: "#dedede",*/}
        //                                         {/*                                        }}*/}
        //                                         {/*                                    >*/}
        //                                         {/*                                        <h4*/}
        //                                         {/*                                            style={{*/}
        //                                         {/*                                                display: "flex",*/}
        //                                         {/*                                                alignItems: "center",*/}
        //                                         {/*                                                justifyContent: "center",*/}
        //                                         {/*                                                marginBottom: "0",*/}
        //                                         {/*                                            }}*/}
        //                                         {/*                                        >*/}
        //                                         {/*                                            <img*/}
        //                                         {/*                                                src={logo}*/}
        //                                         {/*                                                alt="Logo"*/}
        //                                         {/*                                                style={{*/}
        //                                         {/*                                                    height: "140px",*/}
        //                                         {/*                                                    marginRight: "10px",*/}
        //                                         {/*                                                    marginBottom: "0",*/}
        //                                         {/*                                                }}*/}
        //                                         {/*                                            />*/}
        //                                         {/*                                        </h4>*/}
        //                                         {/*                                        <h3*/}
        //                                         {/*                                            style={{*/}
        //                                         {/*                                                display: "flex",*/}
        //                                         {/*                                                alignItems: "center",*/}
        //                                         {/*                                                justifyContent: "center",*/}
        //                                         {/*                                                marginTop: "0",*/}
        //                                         {/*                                            }}*/}
        //                                         {/*                                        >*/}
        //                                         {/*                                            {createItem.LocationID !==*/}
        //                                         {/*                                                "" &&*/}
        //                                         {/*                                                states.length > 0 &&*/}
        //                                         {/*                                                states*/}
        //                                         {/*                                                    .filter(*/}
        //                                         {/*                                                        (i) =>*/}
        //                                         {/*                                                            i.StateId ===*/}
        //                                         {/*                                                            Number(*/}
        //                                         {/*                                                                createItem.LocationID*/}
        //                                         {/*                                                            )*/}
        //                                         {/*                                                    )*/}
        //                                         {/*                                                    .map((r) => r.Title)}{" "}*/}
        //                                         {/*                                            NALDA {createItem.ReportType}{" "}*/}
        //                                         {/*                                            Report{" "}*/}
        //                                         {/*                                            {createItem.Mode !== "All" &&*/}
        //                                         {/*                                                createItem.Date.split(*/}
        //                                         {/*                                                    "-"*/}
        //                                         {/*                                                )[0]}*/}
        //                                         {/*                                        </h3>*/}
        //                                         {/*                                        <h6*/}
        //                                         {/*                                            style={{*/}
        //                                         {/*                                                display: "flex",*/}
        //                                         {/*                                                alignItems: "center",*/}
        //                                         {/*                                                justifyContent: "center",*/}
        //                                         {/*                                            }}*/}
        //                                         {/*                                        >*/}
        //                                         {/*                                            Generated By:{" "}*/}
        //                                         {/*                                            {currentUser.FullName}*/}
        //                                         {/*                                        </h6>*/}
        //                                         {/*                                        <h6*/}
        //                                         {/*                                            style={{*/}
        //                                         {/*                                                display: "flex",*/}
        //                                         {/*                                                alignItems: "center",*/}
        //                                         {/*                                                justifyContent: "center",*/}
        //                                         {/*                                            }}*/}
        //                                         {/*                                        >*/}
        //                                         {/*                                            Date:{" "}*/}
        //                                         {/*                                            {`${currentMonth}/${currentDay}/${currentYear}`}*/}
        //                                         {/*                                        </h6>*/}
        //                                         {/*                                    </div>*/}
        //                                         {/*                                    <div className="panel-body">*/}
        //                                         {/*                                        <div className="table-responsive">*/}
        //                                         {/*                                            <table*/}
        //                                         {/*                                                className="table table-striped table-bordered table-hover"*/}
        //                                         {/*                                                id="dataTables-example"*/}
        //                                         {/*                                            >*/}
        //                                         {/*                                                <thead>*/}
        //                                         {/*                                                <tr>*/}
        //                                         {/*                                                    <th>S/No</th>*/}
        //                                         {/*                                                    <th>Title</th>*/}
        //                                         {/*                                                    <th>LGA</th>*/}
        //                                         {/*                                                    <th>Hectare</th>*/}
        //                                         {/*                                                    <th>Status</th>*/}
        //                                         {/*                                                </tr>*/}
        //                                         {/*                                                </thead>*/}
        //                                         {/*                                                <tbody>*/}
        //                                         {/*                                                {printableData?.length >*/}
        //                                         {/*                                                    0 &&*/}
        //                                         {/*                                                    printableData.map(*/}
        //                                         {/*                                                        (item, index) => {*/}
        //                                         {/*                                                            return (*/}
        //                                         {/*                                                                <tr*/}
        //                                         {/*                                                                    className="gradeC"*/}
        //                                         {/*                                                                    key={index}*/}
        //                                         {/*                                                                >*/}
        //                                         {/*                                                                    <td>*/}
        //                                         {/*                                                                        {index + 1}*/}
        //                                         {/*                                                                    </td>*/}
        //                                         {/*                                                                    <td>*/}
        //                                         {/*                                                                        {item.Title}*/}
        //                                         {/*                                                                    </td>*/}
        //                                         {/*                                                                    <td>*/}
        //                                         {/*                                                                        {lga.length >*/}
        //                                         {/*                                                                            0 &&*/}
        //                                         {/*                                                                            lga*/}
        //                                         {/*                                                                                .filter(*/}
        //                                         {/*                                                                                    (i) =>*/}
        //                                         {/*                                                                                        i.LGAId ===*/}
        //                                         {/*                                                                                        Number(*/}
        //                                         {/*                                                                                            item.LGAId*/}
        //                                         {/*                                                                                        )*/}
        //                                         {/*                                                                                )*/}
        //                                         {/*                                                                                .map(*/}
        //                                         {/*                                                                                    (r) =>*/}
        //                                         {/*                                                                                        r.Title*/}
        //                                         {/*                                                                                )}*/}
        //                                         {/*                                                                    </td>*/}
        //                                         {/*                                                                    <td>*/}
        //                                         {/*                                                                        {*/}
        //                                         {/*                                                                            item.QuantityDescription*/}
        //                                         {/*                                                                        }*/}
        //                                         {/*                                                                    </td>*/}
        //                                         {/*                                                                    <td>*/}
        //                                         {/*                                                                        {item.Status ===*/}
        //                                         {/*                                                                        Number(1)*/}
        //                                         {/*                                                                            ? "Active"*/}
        //                                         {/*                                                                            : "In-Active"}*/}
        //                                         {/*                                                                    </td>*/}
        //                                         {/*                                                                </tr>*/}
        //                                         {/*                                                            );*/}
        //                                         {/*                                                        }*/}
        //                                         {/*                                                    )}*/}
        //                                         {/*                                                <>*/}
        //                                         {/*                                                    <tr className="gradeC">*/}
        //                                         {/*                                                        <td></td>*/}
        //                                         {/*                                                        <td>*/}
        //                                         {/*                                                            <strong>*/}
        //                                         {/*                                                                Active Asset*/}
        //                                         {/*                                                            </strong>*/}
        //                                         {/*                                                        </td>*/}
        //                                         {/*                                                        <td>*/}
        //                                         {/*                                                            <strong>*/}
        //                                         {/*                                                                {activeAsset}*/}
        //                                         {/*                                                            </strong>*/}
        //                                         {/*                                                        </td>*/}
        //                                         {/*                                                    </tr>*/}
        //                                         {/*                                                    <tr className="gradeC">*/}
        //                                         {/*                                                        <td></td>*/}
        //                                         {/*                                                        <td>*/}
        //                                         {/*                                                            <strong>*/}
        //                                         {/*                                                                InActive Asset*/}
        //                                         {/*                                                            </strong>*/}
        //                                         {/*                                                        </td>*/}
        //                                         {/*                                                        <td>*/}
        //                                         {/*                                                            <strong>*/}
        //                                         {/*                                                                {inActiveAsset}*/}
        //                                         {/*                                                            </strong>*/}
        //                                         {/*                                                        </td>*/}
        //                                         {/*                                                    </tr>*/}
        //
        //                                         {/*                                                    <tr className="gradeC">*/}
        //                                         {/*                                                        <td></td>*/}
        //                                         {/*                                                        <td>*/}
        //                                         {/*                                                            <strong>*/}
        //                                         {/*                                                                Total Asset*/}
        //                                         {/*                                                            </strong>*/}
        //                                         {/*                                                        </td>*/}
        //                                         {/*                                                        <td>*/}
        //                                         {/*                                                            <strong>*/}
        //                                         {/*                                                                {total}*/}
        //                                         {/*                                                            </strong>*/}
        //                                         {/*                                                        </td>*/}
        //                                         {/*                                                    </tr>*/}
        //                                         {/*                                                </>*/}
        //                                         {/*                                                </tbody>*/}
        //                                         {/*                                            </table>*/}
        //                                         {/*                                        </div>*/}
        //                                         {/*                                    </div>*/}
        //                                         {/*                                </div>*/}
        //                                         {/*                                /!*End Advanced Tables*!/*/}
        //                                         {/*                            </div>*/}
        //                                         {/*                        </div>*/}
        //                                         {/*                    </div>*/}
        //                                         {/*                </div>*/}
        //                                         {/*            ) : (*/}
        //                                         {/*                <>*/}
        //                                         {/*                    {canPrint && (*/}
        //                                         {/*                        <div className="panel-body">*/}
        //                                         {/*                            <div className="alert alert-info">*/}
        //                                         {/*                                <strong>Sorry!</strong> There is no*/}
        //                                         {/*                                data for the selected report.*/}
        //                                         {/*                            </div>*/}
        //                                         {/*                        </div>*/}
        //                                         {/*                    )}*/}
        //                                         {/*                </>*/}
        //                                         {/*            )}*/}
        //                                         {/*        </div>*/}
        //                                         {/*    </>*/}
        //                                         {/*)}*/}
        //
        //
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </>

           <>
               <div className="col-lg-12 hidenav mb-4 ">
                   <div className="form-group">
                       <select
                           id="ReportType"
                           className="form-control"
                           value={createItem.ReportType}
                           onChange={onEdit}
                       >
                           <option value="">Select Report Type</option>
                           <option value="table">State (Table)</option>
                           <option value="chart">State (Chart)</option>
                           <option value="disability">Disability</option>
                           <option value="course">Course</option>
                           <option value="laptop">Laptop</option>
                           <option value="gender">Gender</option>
                       </select>
                   </div>
               </div>

               {
                   createItem.ReportType === "table" ?
                       <div className={"marginTop"}>
                           <h2 style={{ textAlign: 'center' }}>Applicant Report - Total Number of Applicants by State</h2>
                           <table className={"table table-striped table-hover"}>
                               <thead>
                               <tr>
                                   <th>State</th>
                                   <th>Total Applicants</th>
                                   <th>Total Virtual</th>
                                   <th>Total Hybrid</th>
                                   <th>Total Physical</th>
                               </tr>
                               </thead>
                               <tbody>
                               {sortedStateApplicants.map(([state, count]) => (
                                   <tr key={state}>
                                       <td>{state}</td>
                                       <td>{count.total}</td>
                                       <td>{count.modes.Virtual}</td>
                                       <td>{count.modes.Hybrid}</td>
                                       <td>{count.modes.Physical}</td>
                                   </tr>
                               ))}
                               </tbody>
                           </table>

                           {/* Add a print button */}
                           <button className="hidenav" onClick={() => window.print()} style={{ marginTop: '20px' }}>
                               Print Table
                           </button>




                           <style jsx>{`
        /* Add your custom styles here */
        table {
            width: 100%;
            border-collapse: collapse;
            margin-top: 20px;
        }

        th, td {
            padding: 12px;
            text-align: left;
            border-bottom: 1px solid #ddd;
        }

        th {
            background-color: #f2f2f2;
        }

        /* Add more styles as needed */
    `}</style>
                       </div>
                       :
                       createItem.ReportType === "chart" ?
                       <div className="col-lg-12">
                           <div className="row row-cards">
                               <div className="col-12">
                                   <div className="card">
                                       <div className="card-body">
                                           <h4>Registration Trend By State</h4>
                                           {

                                               <ApexChart
                                                   options={apexChartOption}
                                                   series={apexSeries}
                                                   type="bar"
                                                   width="100%"
                                               />
                                           }
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </div>
                           :
                       createItem.ReportType === "disability" ?
                       <div className="col-lg-12">
                           <div className="row row-cards">
                               <div className="col-12">
                                   <div className="card">
                                       <div className="card-body">
                                           <h4>Registration Trend By Disability</h4>
                                           {

                                               <ApexChart1
                                                   options={apexChartOption1}
                                                   series={apexSeries1}
                                                   type="bar"
                                                   width="100%"
                                               />
                                           }
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </div>
                           :
                           createItem.ReportType === "laptop" ?
                               <div className="col-lg-12">
                                   <div className="row row-cards">
                                       <div className="col-12">
                                           <div className="card">
                                               <div className="card-body">
                                                   <h4>Registration Trend By Laptop</h4>
                                                   {

                                                       <ApexChart2
                                                           options={apexChartOption2}
                                                           series={apexSeries2}
                                                           type="bar"
                                                           width="100%"
                                                       />
                                                   }
                                               </div>
                                           </div>
                                       </div>
                                   </div>
                               </div> :
                               createItem.ReportType === "gender" ?
                                   <div className="col-lg-12">
                                       <div className="row row-cards">
                                           <div className="col-12">
                                               <div className="card">
                                                   <div className="card-body">
                                                       <h4>Registration Trend By Gender</h4>
                                                       {

                                                           <ApexChart3
                                                               options={apexChartOption3}
                                                               series={apexSeries3}
                                                               type="bar"
                                                               width="100%"
                                                           />
                                                       }
                                                   </div>
                                               </div>
                                           </div>
                                       </div>
                                   </div> :
                                   createItem.ReportType === "course" ?
                                       <div className="col-lg-12">
                                           <div className="row row-cards">
                                               <div className="col-12">
                                                   <div className="card">
                                                       <div className="card-body">
                                                           <h4>Registration Trend By Course Applied</h4>
                                                           {

                                                               <ApexChart4
                                                                   options={apexChartOption4}
                                                                   series={apexSeries4}
                                                                   type="bar"
                                                                   width="100%"
                                                               />
                                                           }
                                                       </div>
                                                   </div>
                                               </div>
                                           </div>
                                       </div> :
                           <div className={"alert alert-info text-center"}><h2>Please select report type</h2></div>
               }

</>
    ) : (
        <div>No data available</div>
    );




};

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(Reports);
