import React, {useEffect, useState} from 'react';
import Loader from '../common/loader';
import axios from 'axios';
import { serverLink } from '../url';
import { useLocation } from "react-router-dom";
import { StateData } from '../state_data';
import { connect } from 'react-redux';
import {toast} from "react-toastify";


const ReportByState = (props) => {


    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [lgaList, setLGAList] = useState([]);
    const location = useLocation();

    const [createItem, setCreateItem] = useState({
        ReportType: "",
        State: "",
        LGA: "",
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${serverLink}foundation/dashboard/application/all/report`);
                setData(response.data);
                //console.log("data from report", response.data)
            } catch (error) {
                console.error('Error fetching data:', error);
                toast.error('Error fetching data');
            } finally {
                setIsLoading(false);
            }
        };

        fetchData().then(r => {});

    }, [createItem.ReportType]);

    const NewData = data.filter(x=>x.State === createItem.State)
    const NewDataLGA = data.filter(x=>x.LGA === createItem.LGA)
    useEffect(() => {
        setStateList([...new Set(StateData.map(item => item.StateName))])
    }, [location.pathname]);


    const onEdit = (e) => {
        setCreateItem({
            ...createItem,
            [e.target.id]: e.target.value,
        });
        if(e.target.id === "State"){
            setLGAList([...new Set(StateData.filter(p => p.StateName === e.target.value).map(item => item.LGAName))]);
        }

    };
    const [searchQuery, setSearchQuery] = useState('');
    const filteredData = NewData.filter(item => {
        const fullName = `${item.FirstName} ${item.MiddleName} ${item.Surname}`;
        return fullName.toLowerCase().includes(searchQuery.toLowerCase()) || item.State.toLowerCase().includes(searchQuery.toLowerCase());
    });
    const [searchQuery2, setSearchQuery2] = useState('');
    const filteredLGAData = NewDataLGA.filter(item => {
        const fullName = `${item.FirstName} ${item.MiddleName} ${item.Surname}`;
        return fullName.toLowerCase().includes(searchQuery2.toLowerCase()) || item.State.toLowerCase().includes(searchQuery2.toLowerCase());
    });
    return isLoading ? (
        <Loader />
    ) : data.length > 0 ? (
        <>
            <div className="col-lg-12 hidenav mb-4">
                <div className="row">
                    <div className="form-group col-md-4">
                        <select
                            id="ReportType"
                            className="form-control"
                            value={createItem.ReportType}
                            onChange={onEdit}
                        >
                            <option value="">Select Report Type</option>
                            <option value="state">State</option>
                            <option value="lga">LGA</option>
                        </select>
                    </div>
                    {
                        createItem.ReportType === "state" ?
                            <div className="form-group col-md-4">
                                <select
                                    id="State"
                                    className="form-control"
                                    value={createItem.State}
                                    onChange={onEdit}
                                >
                                    <option value="">Select State</option>

                                    {
                                        stateList.length > 0 &&
                                        stateList.map((state, i) => {
                                            return <option key={i} value={state}>{state}</option>
                                        })
                                    }
                                </select>
                            </div>
                            : createItem.ReportType === "lga" ?
                            <>
                                <div className="form-group col-md-4">
                                    <select
                                        id="State"
                                        className="form-control"
                                        value={createItem.State}
                                        onChange={onEdit}
                                    >
                                        <option value="">Select State</option>

                                        {
                                            stateList.length > 0 &&
                                            stateList.map((state, i) => {
                                                return <option key={i} value={state}>{state}</option>
                                            })
                                        }
                                    </select>
                                </div>

                                <div className="form-group col-md-4">
                                    <select
                                        id="LGA"
                                        className="form-control"
                                        value={createItem.LGA}
                                        onChange={onEdit}
                                    >

                                        <option value="">Select LGA</option>
                                        {
                                            lgaList.length > 0 &&
                                            lgaList.map((lga, i) => {
                                                return <option key={i} value={lga}>{lga}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </>
                            : ""

                    }

                </div>
            </div>

            {
                createItem.ReportType === "state" && NewData.length > 0 ?
                    <div className={"marginTop"}>
                        <h2 style={{ textAlign: 'center' }}>Applicant Report - in <b>{createItem.State}</b> State</h2>
                        <input
                            className="col-md-4 hidenav"
                            type="text"
                            placeholder="Search by Name..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <table className={"table table-striped table-hover"}>
                            <thead>
                            <tr>
                                <th>S/No</th>
                                <th>Applicant Name</th>
                                <th>Programme</th>
                                <th>Training Mode</th>
                                <th>Laptop</th>
                                <th>Disabled</th>
                            </tr>
                            </thead>
                            <tbody>
                            {filteredData.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{`${item.FirstName} ${item.MiddleName} ${item.Surname}`}</td>
                                    <td>{item.CourseName}</td>
                                    <td>{item.TrainingMode}</td>
                                    <td>{item.HasLaptop === 1 ? 'Yes' : 'No'}</td>
                                    <td>{item.IsDisabled === 1 ? 'Yes' : 'No'}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>

                        {/* Add a print button */}
                        <button className="hidenav" onClick={() => window.print()} style={{ marginTop: '20px' }}>
                            Print Table
                        </button>




                        <style jsx>{`
        /* Add your custom styles here */
        table {
            width: 100%;
            border-collapse: collapse;
            margin-top: 20px;
        }

        th, td {
            padding: 12px;
            text-align: left;
            border-bottom: 1px solid #ddd;
        }

        th {
            background-color: #f2f2f2;
        }

        /* Add more styles as needed */
    `}</style>
                    </div>
                    :
                    createItem.ReportType === "lga" && NewDataLGA.length > 0 ?
                    <div className={"marginTop"}>
                        <h2 style={{ textAlign: 'center' }}>Applicant Report - in <b>{createItem.LGA}</b> LGA</h2>
                        <input
                            className="col-md-4 hidenav"
                            type="text"
                            placeholder="Search by Name..."
                            value={searchQuery2}
                            onChange={(e) => setSearchQuery2(e.target.value)}
                        />
                        <table className={"table table-striped table-hover"}>
                            <thead>
                            <tr>
                                <th>S/No</th>
                                <th>Applicant Name</th>
                                <th>Programme</th>
                                <th>Training Mode</th>
                                <th>Laptop</th>
                                <th>Disabled</th>
                            </tr>
                            </thead>
                            <tbody>
                            {filteredLGAData.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{`${item.FirstName} ${item.MiddleName} ${item.Surname}`}</td>
                                    <td>{item.CourseName}</td>
                                    <td>{item.TrainingMode}</td>
                                    <td>{item.HasLaptop === 1 ? 'Yes' : 'No'}</td>
                                    <td>{item.IsDisabled === 1 ? 'Yes' : 'No'}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>

                        {/* Add a print button */}
                        <button className="hidenav" onClick={() => window.print()} style={{ marginTop: '20px' }}>
                            Print Table
                        </button>




                        <style jsx>{`
        /* Add your custom styles here */
        table {
            width: 100%;
            border-collapse: collapse;
            margin-top: 20px;
        }

        th, td {
            padding: 12px;
            text-align: left;
            border-bottom: 1px solid #ddd;
        }

        th {
            background-color: #f2f2f2;
        }

        /* Add more styles as needed */
    `}</style>
                    </div>
                        :
                    <div className={"alert alert-info text-center"}><h2>Please select report type</h2></div>
            }

        </>
    ): (
                <div>No data available</div>
            );

};

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(ReportByState);
