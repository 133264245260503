
export const setLoginDetails = (p) => {
    return {
      type: `SET_CONFERNECE_LOGIN_DETAILS`,
      payload: p,
    };
  };

  export const setDashboardDetails = (p) => {
    return {
      type: `SET_CONFERNECE_LOGIN_DETAILS`,
      payload: p,
    };
  };

export const setApplicantDetails = (p) => {
  return {
    type: `SET_APPLICANT_DETAILS`,
    payload: p,
  };
};
