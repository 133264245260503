// import React from "react";
// import { useState } from "react";
// import PageTitle from "../common/pagetitle/pagetitle";
// import axios from 'axios'
// import { currencyConverter, formatDate, formatDateAndTime, serverLink } from "../url";
// import { showAlert } from "../common/sweetalert/sweetalert";
// import { useEffect } from "react";
// import { connect } from "react-redux";
// import Chart from "react-apexcharts";
// import ReactApexChart from "react-apexcharts";
// import { employment_type_chart_data, partc_mode_chart_data, partc_type_chart_data, reg_trend_chart_data, state_of_training_type_chart_data } from "./chart-data";
// import { Link } from "react-router-dom";
// import Loader from "../common/loader";
//
//
// const DashBoard = (props) => {
//     const [isloading, setIsLoading] = useState(true);
//     const [noticeList, setNoticeList] = useState([]);
//     const [sessionList, setSessionList] = useState([]);
//     const [todaysSession, setTodaysSession] = useState([]);
//     const color = ['success', 'danger', 'info', 'secondary', 'primary', 'warning']
//     const [counts, SetCounts] = useState([]);
//     const [RegChart, setRegChart] = useState([]);
//     const [qualificationTypeChart, setQualificationTypeChart] = useState([]);
//     const [participationModeChart, setParticipationModeChart] = useState([])
//     const [employmentTypeChart, setEmploymentTypeChart] = useState([])
//     const [stateTypeChart, setStateTypeChart] = useState([]);
//
//     const getData = async () => {
//         try {
//             await axios.get(`${serverLink}foundation/dashboard/counts`)
//                 .then((res) => {
//                     SetCounts(res.data);
//                 })
//                 setIsLoading(false)
//             await axios.get(`${serverLink}foundation/dashboard/registration-trend`).then((res) => {
//                 let dt = reg_trend_chart_data(res.data);
//                 setRegChart([dt]);
//             })
//
//             await axios.get(`${serverLink}foundation/dashboard/training-mode-trend`).then((res) => {
//                 let dt = partc_mode_chart_data(res.data);
//                 setParticipationModeChart([dt]);
//             })
//
//             await axios.get(`${serverLink}foundation/dashboard/employment-type-trend`).then((res) => {
//                 let dt = employment_type_chart_data(res.data);
//                 setEmploymentTypeChart([dt]);
//             })
//             await axios.get(`${serverLink}foundation/dashboard/qualification-type-trend`).then((res) => {
//                 let dt = partc_type_chart_data(res.data);
//                 setQualificationTypeChart([dt]);
//
//             })
//             await axios.get(`${serverLink}foundation/dashboard/state-of-training-type-trend`).then((res) => {
//                 let dt = state_of_training_type_chart_data(res.data);
//                 setStateTypeChart([dt]);
//             })
//         } catch (e) {
//
//         }
//     }
//
//     useEffect(() => {
//         getData();
//     }, [])
//
//     return isloading === true ?
//     (<Loader />) : (
//         <>
//             <PageTitle title={["Home", "Dashboard"]} />
//             <section className="section dashboard">
//                 <div className="row">
//                     {
//                         props.loginData[0].Role === "Admin" &&
//                         <div className="col-md-12">
//                             <div className="card">
//                                 <div className="card-body">
//                                     <h5 className="card-title">Applicants Registration Trend</h5>
//                                     <div>
//                                         {
//                                             RegChart.length > 0 &&
//                                             <div id="chart">
//                                                 <ReactApexChart
//                                                     options={RegChart[0].options}
//                                                     series={RegChart[0].series} type="line" width={'100%'} height={350} />
//                                             </div>
//                                         }
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     }
//                     {/* Left side columns */}
//                     {/* <div className="col-lg-8"> */}
//                     <div className="col-lg-12">
//                         <div className="row">
//                             {/* Sales Card */}
//                             {
//                                 props.loginData[0].Role === "Admin" &&
//
//                                 <div>
//                                     {counts.map((item, index) => (
//                                         <div key={index}>
//                                             <div className="row">
//                                                 <div className="col-xxl-3 col-md-6">
//                                                     <Link className="nav-link collapsed" to="/admin/all-applications">
//                                                         <div className="card info-card sales-card">
//                                                             <div className="card-body">
//                                                                 <h5 className="card-title">All Applications</h5>
//                                                                 <div className="d-flex align-items-center">
//                                                                     <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
//                                                                         <i className="bi bi-people" />
//                                                                     </div>
//                                                                     <div className="ps-3">
//                                                                         <h6>{item.allapplicant[0]?.allapplicant || 0}</h6>
//                                                                         <span className="text-success small pt-1 fw-bold">All Applications</span>
//                                                                     </div>
//                                                                 </div>
//                                                             </div>
//                                                         </div>
//                                                     </Link>
//                                                 </div>
//                                                 <div className="col-xxl-3 col-md-6">
//                                                     <Link className="nav-link collapsed" to="/admin/new-applications">
//                                                         <div className="card info-card revenue-card">
//                                                             <div className="card-body">
//                                                                 <h5 className="card-title">New Applications</h5>
//                                                                 <div className="d-flex align-items-center">
//                                                                     <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
//                                                                         <i className="bi bi-calendar2-week" />
//                                                                     </div>
//                                                                     <div className="ps-3">
//                                                                         <h6>{item.submitted[0]?.submitted || 0}</h6>
//                                                                         <span className="text-success small pt-1 fw-bold">New Applications</span>
//                                                                     </div>
//                                                                 </div>
//                                                             </div>
//                                                         </div>
//                                                     </Link>
//                                                 </div>
//
//                                                 <div className="col-xxl-3 col-md-6">
//                                                     <Link className="nav-link collapsed" to="/admin/approved-applications">
//                                                         <div className="card info-card revenue-card">
//                                                             <div className="card-body">
//                                                                 <h5 className="card-title">Approved Applications</h5>
//                                                                 <div className="d-flex align-items-center">
//                                                                     <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
//                                                                         <i className="bi bi-calendar2-week" />
//                                                                     </div>
//                                                                     <div className="ps-3">
//                                                                         <h6>{item.approved[0]?.approved || 0}</h6>
//                                                                         <span className="text-success small pt-1 fw-bold">Approved Applications</span>
//                                                                     </div>
//                                                                 </div>
//                                                             </div>
//                                                         </div>
//                                                     </Link>
//                                                 </div>
//
//
//                                                 <div className="col-xxl-3 col-xl-6">
//                                                     <Link className="nav-link collapsed" to="/admin/rejected-applications">
//                                                         <div className="card info-card customers-card">
//                                                             <div className="card-body">
//                                                                 <h5 className="card-title">Rejected Applications</h5>
//                                                                 <div className="d-flex align-items-center">
//                                                                     <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
//                                                                         <i className="bi bi-people" />
//                                                                     </div>
//                                                                     <div className="ps-3">
//                                                                         <h6>{item.rejected[0]?.rejected || 0}</h6>
//                                                                         <span className="text-danger small pt-1 fw-bold">Rejected Applications</span>
//                                                                     </div>
//                                                                 </div>
//                                                             </div>
//                                                         </div>
//                                                     </Link>
//                                                 </div>
//                                                 {/* <div className="col-xxl-4 col-xl-6">
//                                         <div className="card info-card revenue-card">
//                                             <div className="card-body">
//                                                 <h5 className="card-title">Revenue</h5>
//                                                 <div className="d-flex align-items-center">
//                                                     <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
//                                                         <i className="bi bi-currency-dollar" />
//                                                     </div>
//                                                     <div className="ps-3">
//                                                         <h4>
//                                                             <strong className="text-bold" style={{color:"#080F63"}} >
//                                                                 {counts.length > 0 ? currencyConverter(counts[0].payment[0].TotalAmount) : 0}
//                                                             </strong>
//                                                         </h4>
//                                                         <span className="text-success small pt-1 fw-bold">Total Revenue</span>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div> */}
//
//
//                                                 <div className="col-xxl-12 col-md-12">
//                                                     <div className="card">
//                                                         <div className="row card-body">
//                                                             <div className="col-md-4 pt-3">
//                                                                 <h6>Registration by training mode</h6>
//                                                                 {
//                                                                     participationModeChart.length > 0 &&
//                                                                     <div id="chart">
//                                                                         <ReactApexChart
//                                                                             options={participationModeChart[0].options}
//                                                                             series={participationModeChart[0].series}
//                                                                             type="donut" width={'100%'} />
//                                                                     </div>
//                                                                 }
//                                                             </div>
//                                                             <div className="col-md-4 pt-3">
//                                                                 <h6>Registration by qualification</h6>
//
//                                                                 {
//                                                                     qualificationTypeChart.length > 0 &&
//                                                                     <div id="chart2">
//                                                                         <ReactApexChart
//                                                                             options={qualificationTypeChart[0].options}
//                                                                             series={qualificationTypeChart[0].series}
//                                                                             type="donut" width={'100%'} />
//                                                                     </div>
//                                                                 }
//                                                             </div>
//
//                                                             <div className="col-md-4 pt-3">
//                                                                 <h6>Registration by employment status</h6>
//
//                                                                 {
//                                                                     employmentTypeChart.length > 0 &&
//                                                                     <div id="chart2">
//                                                                         <ReactApexChart
//                                                                             options={employmentTypeChart[0].options}
//                                                                             series={employmentTypeChart[0].series}
//                                                                             type="donut" width={'100%'} />
//                                                                     </div>
//                                                                 }
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//
//
//                                                 <div className="col-xxl-12 col-md-12">
//                                                     <div className="card">
//                                                         <div className="row card-body">
//                                                             <div className="col-md-6 pt-3">
//                                                                 <h6>Registration by Preferred State of Training</h6>
//                                                                 {
//                                                                     stateTypeChart.length > 0 &&
//                                                                     <div id="chart">
//                                                                         <ReactApexChart
//                                                                             options={stateTypeChart[0].options}
//                                                                             series={stateTypeChart[0].series}
//                                                                             type="donut" width={'100%'} />
//                                                                     </div>
//                                                                 }
//                                                             </div>
//                                                             <div className="col-md-6 pt-3">
//                                                                 <h6>Registration by Preferred State of Training</h6>
//                                                                 {
//                                                                     stateTypeChart.length > 0 &&
//                                                                     <div id="chart">
//                                                                         <ReactApexChart
//                                                                             options={stateTypeChart[0].options}
//                                                                             series={stateTypeChart[0].series}
//                                                                             type="pie" width={'100%'} />
//                                                                     </div>
//                                                                 }
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     ))}
//                                 </div>
//                             }
//                         </div>
//                     </div>
//                 </div>
//             </section>
//         </>
//     )
// }
// const mapStateToProps = (state) => {
//     return {
//         loginData: state.LoginDetails,
//     };
// };
//
//
// export default connect(mapStateToProps, null)(DashBoard);
//
import React from "react";
import { useState } from "react";
import PageTitle from "../common/pagetitle/pagetitle";
import axios from 'axios'
import { currencyConverter, formatDate, formatDateAndTime, serverLink } from "../url";
import { showAlert } from "../common/sweetalert/sweetalert";
import { useEffect } from "react";
import { connect } from "react-redux";
import Chart from "react-apexcharts";
import ReactApexChart from "react-apexcharts";
import { employment_type_chart_data, partc_mode_chart_data, partc_type_chart_data, reg_trend_chart_data, state_of_training_type_chart_data } from "./chart-data";
import { Link } from "react-router-dom";
import Loader from "../common/loader";


const DashBoard = (props) => {
    const [isloading, setIsLoading] = useState(true);
    const [noticeList, setNoticeList] = useState([]);
    const [sessionList, setSessionList] = useState([]);
    const [todaysSession, setTodaysSession] = useState([]);
    const color = ['success', 'danger', 'info', 'secondary', 'primary', 'warning']
    const [counts, SetCounts] = useState([]);
    const [RegChart, setRegChart] = useState([]);
    const [qualificationTypeChart, setQualificationTypeChart] = useState([]);
    const [participationModeChart, setParticipationModeChart] = useState([])
    const [employmentTypeChart, setEmploymentTypeChart] = useState([])
    const [stateTypeChart, setStateTypeChart] = useState([]);




    const getData = async () => {
        try {
            await axios.get(`${serverLink}foundation/dashboard/counts`)
                .then((res) => {
                    console.log(res.data)
                    SetCounts(res.data);
                })
            setIsLoading(false)
            await axios.get(`${serverLink}foundation/dashboard/registration-trend`).then((res) => {
                let dt = reg_trend_chart_data(res.data);
                setRegChart([dt]);
            })

            await axios.get(`${serverLink}foundation/dashboard/training-mode-trend`).then((res) => {
                let dt = partc_mode_chart_data(res.data);
                setParticipationModeChart([dt]);
            })

            await axios.get(`${serverLink}foundation/dashboard/employment-type-trend`).then((res) => {
                let dt = employment_type_chart_data(res.data);
                setEmploymentTypeChart([dt]);
            })
            await axios.get(`${serverLink}foundation/dashboard/qualification-type-trend`).then((res) => {
                let dt = partc_type_chart_data(res.data);
                setQualificationTypeChart([dt]);

            })
            await axios.get(`${serverLink}foundation/dashboard/state-of-training-type-trend`).then((res) => {
                let dt = state_of_training_type_chart_data(res.data);
                setStateTypeChart([dt]);
            })
        } catch (e) {

        }
    }

    useEffect(() => {
        getData();
    }, [])

    return isloading === true ?
        (<Loader />) : (
            <>
                <PageTitle title={["Home", "Dashboard"]} />
                <section className="section dashboard">
                    <div className="row">
                        <p>Coming soon</p>
                    </div>
                </section>
            </>
        )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};


export default connect(mapStateToProps, null)(DashBoard);
